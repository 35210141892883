.tabs-btn.active {
    color: #5a44ff;
}

.tabs-btn.active::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background-color: #5a44ff;
    left: 0;
    bottom: 0;
}

.analytics-container .card {
    border-color: rgba(15, 16, 68, 0.1);
}

.analytics-container .text-dark-color {
    color: rgba(15, 16, 68, 0.75);
}

.main_drop_down .list:before {
    position: absolute;
    content: '';
    width: 14px;
    height: 14px;
    background: #4d78ff;
    border-radius: 50%;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

/* drop down style == */

.main_drop_down {
    min-width: 292px;
    max-width: 292px;
    position: relative;
    border-radius: 15px;
    padding-right: 4px;
    background: rgba(255, 255, 255, 0.5);
    height: 48px;
    display: flex;
    align-items: center;
}
.main_drop_down .MuiInputBase-input {
    font-family: 'Poppins';
    font-size: 12px;
    padding-left: 16px;
    font-weight: 500;
    line-height: 21px;
    color: rgba(15, 16, 68, 0.5);
}

.main_drop_down .MuiInputBase-input:hover {
    color: rgba(15, 16, 68, 0.75);
}

.main_drop_down .select_drop_down {
    border: 0;
    width: 100%;
}
#menu- .MuiMenu-list {
    background-color: #fff !important;
    border-radius: 5px;
    padding: 0;
    box-shadow: none;
}
#menu- .MuiMenu-list li {
    padding: 14px !important;
    font-size: 10px;
    display: block;
    font-family: 'Poppins' !important;
    width: 100%;
    height: 24px;
    font-weight: 400;
    color: rgba(15, 16, 68, 0.75);
    background-color: #fff !important;
    align-items: center;
    display: flex;
}
#menu- .MuiMenu-list li:hover {
    background-color: #7dafe21e !important;
    border-radius: 7px;
}
#menu- .MuiPopover-paper {
    box-shadow: 0px 6px 16px 0px rgba(15, 16, 68, 0.16);
    border: 1px solid #000;
    border-radius: 7px;
    max-height: 200px;
    overflow-y: auto;
    width: 292px !important;
    transition: none !important;
    transform-origin: 0 !important;
    margin-top: 24px;
    padding: 10px;
}
.main_drop_down .select_drop_down {
    z-index: 9;
}
#menu- .MuiMenu-list li.disable {
    cursor: auto !important;
    color: #0f104480 !important;
}
.main_drop_down .select_drop_down > div {
    z-index: 999;
    width: 100%;
}
.main_drop_down .select_drop_down fieldset {
    border: 0;
}
/* drop down style end == */

.search-input {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 35px;
    padding: 10px 15px 10px 43px;
    color: rgba(255, 255, 255, 0.5);
}

.search-input:focus {
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.analytics-log-list li {
    background: transparent;
    padding: 5px 0;
}

.analytics-log-list li p .date_time {
    color: rgba(111, 191, 225, 1);
}
.analytics-log-list li .log_dots::before {
    position: absolute;
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5);
    top: 50%;
    transform: translateY(-50%);
    bottom: 0;
    left: -2px;
}

.log-select button span {
    color: #fff;
    opacity: 1;
    line-height: 1;
}

.log-select button span svg {
    fill: #fff;
}

.log-select button {
    background: rgba(255, 255, 255, 0.25);
    width: auto;
    max-width: fit-content;
    padding-left: 34px;
    color: #fff;
    padding-right: 34px;
}

.custom-dropdown button {
    width: fit-content;
    border-color: transparent;
}

.custom-dropdown button:hover {
    border-color: #e6e6ec;
}

.custom-dropdown button .truncate {
    font-size: 14px;
    font-weight: 500;
    color: rgba(15, 16, 68, 0.75);
    line-height: 1;
}

.custom-dropdown button:focus {
    outline: none;
    outline-offset: 0;
    border: none;
}

.custom-date-picker .react-datepicker-wrapper {
    overflow: hidden;
    border-radius: 35px;
    position: relative;
    width: 125px;
    padding: 9px 16px;
    height: 40px;
}

.custom-date-picker .react-datepicker-wrapper:before {
    position: absolute;
    content: '';
    background: url('./../../../../assets/icons/icon-calendar.png') no-repeat;
    width: 13px;
    height: 12px;
    z-index: 1;
    right: 16px;
}

.left-label {
    left: -18px;
    top: 45%;
}
.height-32 {
    min-height: 32px !important;
}
.width-136 {
    width: 136px;
}
.width-282 {
    width: 282px;
}
.width-292 {
    width: 292px;
}
.disable_border_color {
    border: none !important;
    background-color: #fff !important;
}

.height-10 {
    height: 40px !important;
}

.analytics-page-container {
    width: 95vw;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    /* height: -webkit-fill-available ; */
}

.analytics-page-container .trends {
    height: 100%;
    width: calc(58% + 10px);
}

.analytics_container {
    padding-bottom: 10px;
    height: calc(100vh - 220px);
}

@media (max-width: 768px) {
    .analytics_container {
        overflow-y: auto;
    }
}
@media (max-height: 800px) {
    .analytics_container {
        padding-bottom: 10px;
        height: 100%;
        overflow-x: hidden;
    }
    .analytics-page-container .trends {
        min-height: 848px;
        max-height: 484px;
    }
}

@media (max-height: 700px) {
    .analytics_container {
        padding: 10px;
        height: 100%;
    }

    .analytics-page-container .trends {
        min-height: 848px;
        max-height: 484px;
    }
}

.analytics-page-container .risk {
    width: 40%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 !important;
}

.analytics-page-container .risk .analytics_box {
    height: calc(42% + 4px);
}
.analytics-background {
    border-radius: 15px;
}
