.evaluateConatiner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* height: 36vw; */
    min-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    /* background: #60F4C81A !important; */
    border-radius: 15px;
    color: #60f4c8;
}

.evaluate-header {
    width: 100%;
    height: 48px;
    display: flex;
    /* align-items: center; */
    padding: 5px;
    /* background: rgba(96, 244, 200, 0.1); */
    /* border-radius: 15px, 15px, 0px, 0px; */
    color: #60F4C8;
    font-weight: 500;
    font-size: 14px;
}

.evaluate-body {
    display: flex;
    flex-direction: column;
    /* padding: 20px 20px 0px 20px; */
    border-radius: 0px, 0px, 15px, 15px;
    gap: 10px;
    color: #60F4C8BF;
}

.evaluate-body .evaluate_select button {
    padding: 0;
}

.evaluate-body .evaluate-keywords button {
    padding: 0;
}

.btn-simulate {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 8px;
    border-radius: 7px;
    border: 8px;
    background: #60F4C8D9;
    color: #0F1044BF;
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 500;
}

.btn-simulate:disabled {
    opacity: 0.5;
}

hr {
    margin-top: 5px;
    border: 1px solid #60F4C833;
}

.converstaion-length-container {
    background: #60F4C81A !important;
    height: 32px;
    border-radius: 10px;
    padding: 4px;
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selected-conversation-type {
    background: #60F4C81A !important;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #60F4C800;
}

.conversation-type:hover {
    background: #60F4C81A !important;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #60F4C800;
}

.conversation-type {
    height: 24px;
    width: 24px;
    padding: 4px;
    gap: 4px;
}

.mood-user-1 {
    width: 65px;
    color: #B0E1FD;
    font-size: 12px;
    overflow: hidden;
    scrollbar-width: none !important;
    display: flex;
    align-items: center;
    position: relative;
    /* For Firefox */
}

.mood-user-1::-webkit-scrollbar .mood-user-2::-webkit-scrollbar {
    display: none !important;
    /* For Chrome, Safari, and Edge */
}

.mood-user-name {
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
}

.mood-user-name span {
    display: inline-block;
    transition: transform 0.5s ease;
}

.mood-user-1:hover .mood-user-name.overflow span,
.mood-user-2:hover .mood-user-name.overflow span,
.mood-user-1:focus .mood-user-name.overflow span,
.mood-user-2:focus .mood-user-name.overflow span {
    animation-name: scroll-text;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
}

.mood-user-2 {
    width: 65px;
    color: #8799FF;
    font-size: 12px;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none !important;
    /* For Firefox */
    display: flex;
    align-items: center;
    position: relative;
}

@keyframes scroll-text {
    0% {
        transform: translateX(0%);
    }

    90% {
        transform: translateX(-100%);
    }

    95% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(0%);
    }
}

.mood-text {
    color: #60F4C880;
    font-size: 10px;
}

.scenarios-select-container {
    border-radius: 10px;
    width: 100%;
    padding: 8px;
}

.scenarios-select-placeholder {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: 400;
    color: #60F4C8BF;
}

.scenarios-menu-item {
    color: #60F4C8BF;
    font-size: 12px;
    font-family: 'Poppins';
}

.evaluate-response-container {
    /* padding: 10px 20px 10px 0px; */
    flex-grow: 1;
    overflow-y: auto;
}

.evaluate-scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 80px;
}

.evaluate-scrollbar::-webkit-scrollbar-track {
    border-radius: 50px;
    background-color: #FFFFFF0D;
}

.evaluate-scrollbar::-webkit-scrollbar-thumb {
    width: 4px;
    background: #FFFFFF0D;
    border-radius: 50px;
    /* border: 3px solid #f6f7ed; */
}

.evaluate-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(15, 16, 68, 0.3);
}

.global-score {
    padding: 8px;
    border-radius: 7px;
    background: #11113F;
    color: #B0E1FD;
    display: flex;
    font-size: 10px;
    font-weight: 400;
    align-items: center;
}

.global-score-user1 {
    background: #11113F;
    color: #B0E1FD;
}

.global-score-user2 {
    background: #11113F;
    color: #8799FF;
}

.evaluate-response {
    height: auto;
    /* height: 384px;
    max-height: 384px; */
    overflow-y: hidden;
    overflow-x: hidden !important;
    width: 100%;
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    /* padding: 10px 0px 20px 24px; */
}

.user-score {
    background: #000000;
    width: 24px;
    height: 24px;
    color: #60F4C8;
    border-radius: 7px;
    padding: 8px;
    font-size: 10px;
    position: relative;
}

.user-flag {
    position: absolute;
}

.user1-response {
    color: #B0E1FD;
}

.user2-response {
    color: #8799FF;
}

.edit {
    display: flex;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #60F4C81A;
    padding: 8px;
    gap: 8px;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
}

.edit:hover {
    background-color: #60F4C833;
}

.edit:hover svg {
    fill: #60F4C8BF;
    /* Change the fill color of the icon on hover */
}

.edit_tooltip_box {
    min-width: fit-content;
    height: auto;
    padding: 5px;
    top: 32px;
    position: absolute;
    border-radius: 7px;
    display: none;
    margin: 0;
    align-items: center;
    background: #0014ff;
    overflow: hidden;
    justify-content: center;
}

.edit_tooltip_box p {
    font-family: 'Poppins';
    font-size: 10px;
    text-wrap: nowrap;
    font-weight: 400;
    display: block;
    line-height: 15px;
    color: #ffffff;
}

.edit_btn:hover .edit_tooltip_box {
    display: flex;
    right: 0;
    left: auto;
    overflow: hidden;
}

.change-response {
    background: linear-gradient(198deg, #3E80FF 4.29%, #9859FF 87.74%);
    width: 24px;
    height: 24px;
    color: #60F4C8;
    border-radius: 7px;
    padding: 8px;
    font-size: 8px;
    position: relative;
}

.response_tooltip_box {
    min-width: fit-content;
    height: auto;
    padding: 5px;
    position: absolute;
    border-radius: 7px;
    display: none;
    margin: 0;
    align-items: center;
    background: #0014ff;
    overflow: hidden;
    justify-content: center;
}

.response_tooltip_box p {
    font-family: 'Poppins';
    font-size: 10px;
    text-wrap: nowrap;
    font-weight: 400;
    display: block;
    line-height: 15px;
    color: #ffffff;
    overflow: hidden;
}

.change_response_btn:hover .response_tooltip_box {
    display: flex;
    overflow: visible;
    z-index: 1;
    transform: translate(0%, 10%);
}

.restart_btn {
    position: relative;
}

.restart_btn:hover .edit_tooltip_box {
    display: flex;
    transform: translate(-40%, -60%);
    overflow: hidden;
    z-index: 1;
}

.evaluate-body .evaluate_select button {
    padding: 0;
}

#solar-system .square {
    width:  0.26vw;
    height:  0.26vw;
    background: rgba(111, 191, 225, 1);
    position: fixed;
    transform: rotate(45deg);
    visibility: hidden;
}

#solar-system .right_marker .square {
    right: -6.771vw;
    top: 0.208vw;
}

#solar-system .left_marker .square {
    left: -1.823vw;
    top: 0.208vw;
}

#solar-system .square-shadow {
    box-shadow: 0px 0px 15px 0px rgba(111, 191, 225, 1);
}
.evaluate_option_box{
    overflow: unset  !important;
    border-radius: unset;
}

.space-between {
    gap: 2.5em;
}