.position-relative {
    position: relative;
}
.position-absolute-right {
    position: absolute;
    right: 0;
}

.menu-list {
    width: 200px;
    border-radius: 7px;
    box-shadow: 0px 4px 20px rgba(15, 16, 68, 0.18);
    background-color: #fff;
}

.delete-warning-modal {
    background-color: #fffaeb;
    padding: 16px;
    gap: 16px;
    border-radius: 15px;
    opacity: 0px;
}

.with-bullets {
    list-style-type: disc;
    margin-left: 20px;
}
