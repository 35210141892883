/* log css  */
.log_filter_select {
    position: relative;
}

.log_filter_select .m_select {
    width: auto;
}
.log_filter_select .log-select button {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 32px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    padding: 0 27px;
    background-color: rgb(64 65 96 / 58%);
    max-width: 100% !important;
}

.log_filter_select .log-select button:hover {
    border-color: rgba(255, 255, 255, 0.5);
}
.log_filter_select .log-select button span {
    line-height: unset;
    color: rgba(255, 255, 255, 0.75);
}
.log_filter_select .log-select_disable button {
    padding-right: 53px;
}
.log_filter_select .log-select button svg path {
    fill: rgba(255, 255, 255, 0.75);
}
.log_filter_select ul {
    background-color: rgba(15, 16, 68, 1);
    padding: 5px;
}
.log_filter_select ul li {
    height: 32px;
    display: flex;
    align-items: center;
}
.log_filter_select ul li svg {
    display: none;
}
.log_filter_select ul li span {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(255, 255, 255, 0.75);
}
.log_filter_select svg path {
    fill: #fff;
}

.log_filter_select ul li .font-bold {
    font-weight: normal !important;
}

.log_filter_select ul li:hover {
    background-color: rgba(255, 255, 255, 0.25);
}
.log_filter_select ul li[aria-selected='true'] {
    background-color: rgba(255, 255, 255, 0.25);
}
.scroll_analytics_log {
    height: calc(100% - 90px);
}
.scroll_analytics_log .analytics-log-list {
    padding-right: 10px;
    height: 100%;
    margin-top: 15px;
    overflow-y: auto;
}

.analytics-log-list li p {
    padding-left: 0;
    color: rgba(255, 255, 255, 0.75);
    font-family: Courier Prime;
    font-size: 12px;
    font-weight: 400;
    position: relative;
    line-height: 13.48px;
    text-align: left;
}
.analytics-log-list li p.protocol_nuances,
.analytics-log-list li p.protocol_name {
    padding-right: 14px;
}
.analytics-log-list li p span {
    white-space: nowrap;
    display: block;
    gap: 10px;
}
.analytics-log-list li p:hover .scroll_span {
    animation: scroll_text 5s linear infinite;
}

@keyframes scroll_text {
    0% {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(5%);
    }
}
.analytics-log-list li span {
    margin-left: 18px;
}

.analytics-log-list li p span:hover {
    text-overflow: unset;
}
.analytics-log-list li p label {
    display: block;
    width: 100%;
    padding: 0 10px 0 0;
    margin-left: 3px;
}
.analytics-log-list li p .date_time {
    width: 171px;
    margin-left: 8px;
}
.analytics-log-list li p .protocolType {
    width: 35px;
    display: flex;
    gap: 10px;
}
.analytics-log-list li p .protocolType img {
    margin-top: 4px;
}
.analytics-log-list li p .name {
    width: 226px;
}

.analytics-log-list li p .nuances {
    width: 210px;
}

.analytics-log-list li .score {
    width: 183px;
    text-align: end;
}
.analytics-log-list li .score span {
    display: inline;
    padding-left: 10px;
    margin-right: 33px;
}
.graph_box .search-input {
    border-radius: 7px !important;
    height: 32px;
    max-width: 427px;
}

.log_filter_select .starIcon {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 50%;
    margin-left: auto;
    justify-content: flex-end;
}
.log-select_disable {
    opacity: 0.7;
}

.log_filter_select .starIcon .main_star_button {
    margin: 0 16px;
}

.log_filter_select .m_select > div {
    left: 10px !important;
}

.log_header {
    margin-top: 25px;
}

.log_header ul li {
    font-family: Poppins;
    font-size: 12px;
    gap: 3px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: rgba(255, 255, 255, 0.75);
}

.log_header ul li:nth-child(1) {
    max-width: 197px;
    width: 100%;
    padding-left: 7px;
}
.log_header ul li:nth-child(2) {
    max-width: 56px;
    width: 100%;
}
.log_header ul li:nth-child(3) {
    max-width: 265px;
    width: 100%;
}
.log_header ul li:nth-child(4) {
    max-width: 190px;
    width: 100%;
    padding-right: 10px;
    justify-content: end;
}

.log_header ul li.icon1 svg path:nth-child(2) {
    fill: transparent !important;
}
.log_header ul li.icon2 svg path:nth-child(1) {
    fill: transparent !important;
}

.analytics-log-list .no_data {
    color: #fff;
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
    font-size: 12px;
    text-transform: capitalize;
}

@media (max-height: 900px) {
    /* .scroll_analytics_log {
        height: 200px;
    } */
    .analytics-page-container {
        height: 100%;
        padding: 0 0 20px 0;
    }
    .risk_box {
        gap: 20px;
    }
    /* .analytics-page-container .risk .analytics_box {
        margin: 20px 0;
    } */
}
@media (max-height: 800px) {
    .analytics-page-container .risk .analytics_box {
        height: 85%;
    }
    .scroll_analytics_log {
        height: 300px;
    }
}
.logs_box .empty_box {
    align-items: center;
    height: 90%;
    overflow-y: auto;
}

.logs_box .empty_box > div {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.logs_box .empty_box p,
.logs_box .empty_box h4 {
    color: #fff !important;
    font-weight: 400;
}
.logs_box .empty_box .empty_graph {
    height: auto;
    width: auto;
}
.logs_box .empty_box p span {
    color: #fff !important;
    font-weight: 700;
}
.logs_box .empty_box button {
    margin-top: 12px;
}
.logs_box .empty_box p b {
    color: rgba(57, 188, 249, 1);
}

.logs_box .search_box {
    width: 250px;
    height: 32px;
    margin-right: 30px;
}
.logs_box .search_box::before {
    position: absolute;
    content: '';
    width: 1px;
    height: 80%;
    border: 1px solid rgba(255, 255, 255, 0.075);
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
}

.logs_box .search_box input {
    width: 0;
    border: 0;
    font-size: 12px;
    height: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    outline: none;
    color: rgba(255, 255, 255, 0.5);
    background-color: transparent;
}

.logs_box .search_box.show_search input {
    width: 100%;
    padding: 0 10px;
}
.logs_box .search_box.show_search input:hover {
    color: rgba(255, 255, 255, 0.75);
}
.logs_box .search_box .searchIocn {
    position: absolute;
    right: 15px;
    top: 50%;

    transform: translateY(-50%);
}
.logs_box .search_box .searchIocn svg path {
    fill: rgba(255, 255, 255, 0.5);
    fill-opacity: 0.7;
}

.logs_box .search_box.show_search .searchIocn svg path,
.logs_box .search_box:hover .searchIocn svg path {
    fill-opacity: 1;
}
.logs_box .search_box.show_search {
    border: 0;
}
