/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
    min-height: 100%;
    background-color: #fafafa;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: auto;
    padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}
.gap-2 {
    width: unset;
}
@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url('./assets/fonts/Poppins-Italic.ttf') format('truetype');
    font-style: italic;
}

video {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.scrollbar::-webkit-scrollbar {
    background: rgba(15, 16, 68, 0.03);
    width: 8px;
    border-radius: 5px;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: rgba(15, 16, 68, 0.1);
    border-radius: 50px;
}

.call_notification {
    background: rgb(235, 235, 235);
    background: -moz-linear-gradient(
        180deg,
        rgba(235, 235, 235, 1) 0%,
        rgba(249, 241, 255, 1) 43%,
        rgba(181, 181, 181, 1) 100%
    );
    background: -webkit-linear-gradient(
        180deg,
        rgba(235, 235, 235, 1) 0%,
        rgba(249, 241, 255, 1) 43%,
        rgba(181, 181, 181, 1) 100%
    );
    background: linear-gradient(
        180deg,
        rgba(235, 235, 235, 1) 0%,
        rgba(249, 241, 255, 1) 43%,
        rgba(181, 181, 181, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ebebeb", endColorstr="#b5b5b5", GradientType=1);
    border-radius: 5px;
}

.main_eva_component {
    background: rgba(255, 255, 255, 0.35);
    height: 100%;
    border-radius: 15px;
    margin: 10px 0 0 !important;
}

.shadow-mobile-nav {
    z-index: 999;
}

.main_loader {
    position: fixed;
    width: 100vw;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100vh;
}

body::-webkit-scrollbar {
    display: none;
}

#loading {
    border: 3px solid transparent;
    /* Light grey */
    border-top: 3px solid #373bba;
    /* Blue */
    border-left: 3px solid #373bba;
    /* Blue */
    border-bottom: 3px solid #373bba;
    /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spinLoading 0.8s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spinLoading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.bg-backgroundColor {
    background-color: rgba(238, 238, 242, 1);
}

/* PURE CSS LOADER */
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #5043a3;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #5043a3 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Loader GIF */
#preloader {
    position: absolute;
    width: 17%;
    height: 17%;
    z-index: 999;
    overflow: visible;
    background: url('./assets/blueberry/loading.gif') no-repeat center center;
    background-size: contain;
}

.progressbar {
    position: relative;
    display: flex;
    justify-content: space-between;
    counter-reset: step;
}

.progressdot {
    position: relative;
    display: flex;
    justify-content: space-between;
    counter-reset: none;
}

.progress-dot-step {
    width: 8px;
    height: 8px;
    background-color: rgba(15, 16, 68, 0.15);
    border-radius: 50%;
    border-width: 1px;
    border-color: rgba(15, 16, 68, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    font-size: 12px;
    color: transparent;
    margin-left: 5px;
}

.progress-dot-active {
    background-color: rgba(15, 16, 68, 0.75);
    color: white;
}

.progress-step {
    width: 31px;
    height: 31px;
    background-color: white;
    border-radius: 50%;
    border-width: 1px;
    border-color: #cacbf6;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    font-size: 12px;
    color: #0f1044;
    font-weight: 500;
}

.progressbar::before,
.progress {
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    width: 100%;
    transform: translateY(-50%);
    background-color: #cacbf6;
    z-index: 1;
    border: 1px solid #cacbf6;
}

.progress {
    background-color: #373bba;
    width: 50%;
    transition: 0.3s;
}

.progress-step::before {
    counter-increment: step;
    content: counter(step);
}

.progress-step-active {
    background-color: #373bba;
    color: white;
}

.select-icon {
    fill: #5e37ba;
}

.profile-avatar {
    outline: 2px dashed #373bba;
}

textarea {
    resize: none;
}

.footer-signin li a {
    padding: 0px 4px;
}

.footer-signin li::before {
    content: '|';
}

.footer-signin li::before {
    content: '|';
}

.footer-signin li:first-child::before {
    display: none;
}

.footer-signin li:last-child::before {
    content: '-';
}

.white-svg path {
    fill: #ffffff;
}

.gray-svg path {
    fill: #c2c4c8;
}

.black-svg path {
    fill: #000000;
}

.purple-svg path {
    fill: #5e37ba;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.outer {
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    overflow: hidden;
    /* white-space: nowrap; */
}

.inner {
    float: right;
}

/*To remove the autofill yellow background on firefox */
input {
    filter: none;
}

.red-svg path {
    fill: #f32a2a;
}

.StripeInput--base {
    padding: 13px 16px;
    border-radius: 50px;
    border: 1px solid #0f10441a;
    cursor: text;
    font-size: 12px !important;
    font-family: Poppins, sans-serif;
    background-color: white;
    color: #0f1044bf;
}

.StripeInput--base:hover {
    border-color: #0f104433;
}

.button:hover .st0 {
    fill: white;
}

.datepicker-wrapper-round {
    border-radius: 50px !important;
    padding: 1px !important;
    width: 122px !important;
    height: 40px !important;
    border-color: #0f10441a !important;
    color: #0f1044bf !important;
}

.datepicker-wrapper-round:hover {
    border-color: #0f104433 !important;
}

.publish-eva-dynamic-text::-webkit-scrollbar {
    width: 5px;
    height: 88px;
}

.publish-eva-dynamic-text::-webkit-scrollbar-track {
    border-radius: 50px;
    background-color: rgba(15, 16, 68, 0.03);
}

.publish-eva-dynamic-text::-webkit-scrollbar-thumb {
    background: rgba(15, 16, 68, 0.1);
    border-radius: 50px;
}

/* @layer utilities { */
.scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 80px;
}

.scrollbar::-webkit-scrollbar-track {
    border-radius: 50px;
    background-color: rgba(15, 16, 68, 0.03);
}

.scrollbar::-webkit-scrollbar-thumb {
    background: rgba(15, 16, 68, 0.1);
    border-radius: 50px;
    /* border: 3px solid #f6f7ed; */
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(15, 16, 68, 0.3);
}

.x-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.x-scrollbar::-webkit-scrollbar-track {
    border-radius: 50px;
    background-color: rgba(15, 16, 68, 0.03);
}

.x-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(15, 16, 68, 0.1);
    border-radius: 50px;
    /* border: 3px solid #f6f7ed; */
}

.x-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(15, 16, 68, 0.3);
}

.line-clamp {
    display: -webkit-box;
    /* width: 100%; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.not-found {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 320px;
    line-height: 480px;
    align-items: center;
    text-align: center;
    background: linear-gradient(110.73deg, rgba(255, 255, 255, 0.5) 39.63%, rgba(255, 255, 255, 0) 83.31%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
    z-index: 999;
}

[data-title]:after {
    content: attr(data-title);
    background-color: #ffff;
    color: rgba(15, 16, 68, 0.75);
    font-size: 12px;
    position: absolute;
    padding: 5px;
    bottom: -1.1em;
    left: 50%;
    white-space: nowrap;
    box-shadow: 0px 3px 6px rgba(0, 8, 255, 0.16);
    opacity: 0;
    /* border: 1px solid #111111; */
    border-radius: 5px;
    z-index: 99999;
    visibility: hidden;
    overflow: hidden;
}

[data-title] {
    position: relative;
}

.add-metric[data-title]:hover:after {
    content: attr(data-title);
    transform: translateX(-80%) translateY(70%);
    overflow: visible !important;
}

.metric-filter[data-title]:hover:after {
    content: attr(data-title);
    transform: translateX(-30%) translateY(70%);
    overflow: visible !important;
}

.metric-category[data-title]:hover:after {
    content: attr(data-title);
    transform: translateX(-5%) translateY(70%);
    overflow: visible !important;
}

.lock-btn[data-title]:hover:after {
    content: attr(data-title);
    transform: translateX(-50%) translateY(70%);
    overflow: visible !important;
}

.req-level[data-title]:hover:after {
    content: attr(data-title);
    transform: translateX(-80%) translateY(80%);
    overflow: visible !important;
}

.switch-headless[data-title]:hover:after {
    content: attr(data-title);
    transform: translateX(-50%) translateY(70%);
    overflow: visible !important;
}

.fav-btn[data-title]:hover:after {
    content: attr(data-title);
    transform: translateX(-50%) translateY(70%);
    overflow: visible !important;
}

.edit-icon[data-title]:hover:after,
.cross-icon[data-title]:hover:after,
.thumbs-up[data-title]:hover:after,
.thumbs-down[data-title]:hover:after {
    content: attr(data-title);
    transform: translateX(-50%) translateY(70%);
    overflow: visible !important;
}

.slide {
    /* position: absolute; */
    left: -100px;
    /* width: 100px;
        height: 100px; */
    /* background: blue; */
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.5s forwards;
    animation-delay: 2s;
}

@-webkit-keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    position: absolute;
    top: 100%;
    /* Position below the element */
    transform: translateX(-50%);
    margin-top: 8px;
    padding: 8px;
    background-color: rgba(0, 20, 255, 0.75);
    /* Tooltip background color */
    color: white;
    text-align: center;
    border-radius: 4px;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
    font-size: 10px;
    width: 300px !important;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltiptext::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 20, 255, 0.75) transparent;
}

ul.space-scroll {
    display: flex;
    align-items: center;
    list-style: none;
    /* max-width: 180px; */
    /* max-width: 100%; */
    overflow-x: scroll;
    scroll-behavior: smooth;
}

ul.space-scroll::-webkit-scrollbar {
    background: transparent;
    /* make scrollbar transparent */
    -webkit-appearance: none;
    width: 0;
    height: 0;
}

ul.space-scroll li {
    /* background:#FF61F6; */
    /* color: #fff; */
    padding: 2px;
    border-radius: 4px;
    margin: 0 4px;
    white-space: nowrap;
}

ul.space-scroll div.space-thread {
    padding: 2px 6px;
    border-radius: 15px;
    margin: 0 4px;
    white-space: nowrap;
}

.graph-tooltip {
    width: auto;
    height: auto;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 3, 141, 0.06);
    font-size: 11px;
    max-width: 400px;
    padding: 10px;
    position: absolute;
    margin-left: 35px;
    transform: translateY(-50%);
    border-radius: 15px;
}

.scatterplotCircle {
    cursor: pointer;
    opacity: 1;
    stroke: white;
    fill-opacity: 1;
    stroke-width: 2px;
    -webkit-filter: drop-shadow(0px 0px 4px #e8880e);
    filter: drop-shadow(0px 0px 4px #e8880e);
    /* box-shadow: 0px 3px 6px rgba(0, 3, 141, 0.06); */
}

.space-post:hover {
    border: 1px solid rgba(15, 16, 68, 0.05);
    background: rgba(15, 16, 68, 0.01);
}

.video-timeline {
    -webkit-appearance: none;
    margin-right: 15px;
    /* width: 200px; */
    height: 7px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    background-image: linear-gradient(#373bba, #373bba);
    background-size: 0% 100%;
    background-repeat: no-repeat;
}

.video-timeline::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 1;
    transition: all 0.1s;
    background-color: rgba(235, 235, 235, 0.5);
    border: 2px solid rgba(235, 235, 235, 0.5);
}

.video-timeline::-moz-range-thumb {
    /* -webkit-appearance: none; */
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 1;
    transition: all 0.1s;
    background-color: rgba(235, 235, 235, 1);
    border: 2px solid rgba(235, 235, 235, 0.5);
}

.video-timeline::-ms-thumb {
    /* -webkit-appearance: none; */
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 1;
    transition: all 0.1s;
    background-color: rgba(235, 235, 235, 1);
    border: 2px solid rgba(235, 235, 235, 0.5);
}

.video-timeline::-webkit-slider-thumb:hover {
    background-color: rgba(235, 235, 235, 1);
    border: 2px solid rgba(235, 235, 235, 0.5);
}

.video-timeline:hover::-webkit-slider-thumb {
    opacity: 1;
}

.video-timeline::-moz-range-thumb:hover {
    background-color: #943f65;
}

.video-timeline:hover::-moz-range-thumb {
    opacity: 1;
}

.video-timeline::-ms-thumb:hover {
    background-color: #943f65;
}

.video-timeline:hover::-ms-thumb {
    opacity: 1;
}

.video-timeline::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

.video-timeline::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: #373bba;
}

.video-timeline::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: #373bba;
}

.sound-range {
    -webkit-appearance: none;
    /* margin-right: 15px; */
    /* width: 200px; */
    height: 5px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    background-image: linear-gradient(#ffff, #ffff);
    background-size: 50% 100%;
    background-repeat: no-repeat;
}

.sound-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all 0.1s;
    background-color: rgba(235, 235, 235, 0.5);
    border: 2px solid rgba(235, 235, 235, 0.5);
}

.sound-range::-moz-range-thumb {
    /* -webkit-appearance: none; */
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all 0.1s;
    background-color: rgba(235, 235, 235, 1);
    border: 2px solid rgba(235, 235, 235, 0.5);
}

.sound-range::-ms-thumb {
    /* -webkit-appearance: none; */
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all 0.1s;
    background-color: rgba(235, 235, 235, 1);
    border: 2px solid rgba(235, 235, 235, 0.5);
}

.sound-range::-webkit-slider-thumb:hover {
    background-color: rgba(235, 235, 235, 1);
    border: 2px solid rgba(235, 235, 235, 0.5);
}

.sound-range:hover::-webkit-slider-thumb {
    opacity: 1;
}

.sound-range:hover::-ms-thumb {
    opacity: 1;
}

.sound-range::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

.controlsTime {
    color: white;
}

.notPlaying {
    -webkit-appearance: none;
    margin-right: 15px;
    /* width: 200px; */
    height: 7px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    background-image: linear-gradient(#373bba, #373bba);
    background-size: 0% 100%;
    background-repeat: no-repeat;
}

.notPlaying::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all 0.1s;
    background-color: rgba(235, 235, 235, 0.5);
    border: 2px solid rgba(235, 235, 235, 0.5);
}

.notPlaying::-moz-range-thumb {
    /* -webkit-appearance: none; */
    width: 1em;
    height: 1em;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: all 0.1s;
    background-color: rgba(235, 235, 235, 1);
    border: 2px solid rgba(235, 235, 235, 0.5);
}

.input-placeholder {
    position: relative;
}

.input-placeholder input:valid + .space-placeholder {
    display: none;
}

.space-placeholder {
    position: absolute;
    pointer-events: none;
    /* top: 2px;
        bottom: 2px; */
    text-align: center;
    left: 0;
    right: 0;
    top: 2px;
    bottom: 0;
    margin: auto;
    color: rgba(15, 16, 68, 0.75);
    font-weight: 600;
}

.space-placeholder span {
    color: rgba(197, 17, 98, 1);
    margin-left: 2px;
}

.link-background {
    /* width: 400px;
        height: 200px; */
    border: 0.61px solid #ffffff;
    background: linear-gradient(to bottom right, rgba(162, 179, 240, 0.03) 50%, transparent 50%),
        linear-gradient(to bottom left, rgba(162, 179, 240, 0.07) 50%, transparent 50%), rgba(15, 16, 68, 0);
}

/* .loading-dots {
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        animation: loading-dots 1s infinite linear alternate;
        animation-delay: 0.5s;
    }

    .loading-dots::before, .loading-dots::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
    }

    .loading-dots::before {
        left: -15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        animation: loading-dots 1s infinite alternate;
        animation-delay: 0s;
    }

    .loading-dots::after {
        left: 15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #9880ff;
        color: #9880ff;
        animation: loading-dots 1s infinite alternate;
        animation-delay: 1s;
    }

    @keyframes loading-dots {
        0% {
          background-color: #9880ff;
        }
        50%, 100% {
          background-color: rgba(152, 128, 255, 0.2);
        }
    } */

.bounce {
    width: 5px;
    height: 5px;
    background: #fff;
    border-radius: 100%;
    float: left;
    margin-right: 5px;
    animation: bounce 1s infinite alternate;
    animation-delay: 0s;
}

.bounce-0 {
    animation: bounce 1s infinite;
    animation-delay: 0.1s;
    background: #f4c8da;
}

.bounce-1 {
    animation: bounce 1s infinite;
    animation-delay: 0.3s;
    background: #cab6ee;
}

.bounce-2 {
    animation: bounce 1s infinite ease;
    animation-delay: 0.5s;
    background: #a6a6ff;
}

@keyframes bounce {
    0%,
    100% {
        opacity: 1;
    }

    60% {
        opacity: 0;
    }
}

.file-extension-container {
    display: block;
    position: relative;
    /* padding-left: 35px;
        margin-bottom: 20px; */
    cursor: pointer;
    /* font-size: 25px; */
}

/* Hide the default checkbox */
.file-extension-container input {
    visibility: hidden;
    cursor: pointer;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    border: 1px solid rgba(15, 16, 68, 0.15);
    border-radius: 4px;
}

/* .file-extension-container:hover input ~ .checkmark {
        background-color: gray;
    } */

.file-extension-container input:checked ~ .checkmark {
    background-color: #373bba;
    /* align-items: center; */
}

/* Create the mark/indicator (hidden when not checked) */
.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

/* Show the mark when checked */
.file-extension-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the mark/indicator */
.file-extension-container .checkmark:after {
    left: 5.5px;
    top: 2.5px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 1.5px 1.5px 0;
    transform: rotate(45deg);
    align-items: center;
}

.clickOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1;
}

.edit {
    right: 10px;
    bottom: 0px;
}

/* Create Eva Orbit */
.main-div {
    border: 1px solid rgba(15, 16, 68, 0.07);
    background: rgba(15, 16, 68, 0.01);
}

.eva-button-vertical-divider {
    border-color: #3d3d53;
}

.eva-close-icon {
    fill: #686779;
}

/* Gradient line */
.eva-gradient-line {
    background-color: rgba(15, 16, 68, 0.1);
    height: 4;
    flex: 1;
}

/* Eva Popup */
#solar-system {
    position: relative;
    width: 41.667vw;
    height: calc(100vh - 350px);
    display: flex;
    justify-content: center;
}

.current_dot_hover {
    /* scale: 1.1 !important; */
    font-size: 0.87vw !important;
    border: 2px solid #0f1044bf;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 0px #6884e180;
}

#solar-system .dot_hover:hover {
    scale: 1.1 !important;
    border: 2px solid #0f1044bf;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 0px #6884e180;
}

.orbit-center-img {
    z-index: -1;
    width: 8.021vw;
    height: 8.021vw;
    border-radius: 50%;
    background: #121245;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#solar-system .wrapper {
    position: absolute;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 7.813vw;
    height: 7.813vw;
    background-color: #11113f;
    border-radius: 50%;
}

.orbit-center-img img {
    scale: 1.22;
    width: 154px;
    height: 154px;
}

.orbit {
    position: absolute;
    border: 2px solid rgba(74, 149, 214, 0.2);
    border-radius: 50%;
    transform-style: preserve-3d;
    animation-name: orbit;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 26.25vw;
    height: 6.875vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.orbit .orbit_overly {
    position: fixed;
    left: 6px;
    border-radius: 50%;
    top: 2px;
    background-color: transparent;
    width: 98%;
    height: 98%;
    cursor: auto;
}

.orbit:hover {
    z-index: 99;
}

.orbit.enabled {
    border-color: rgba(111, 191, 225, 0.5);
}

.orbit.disabled {
    border-color: rgba(74, 149, 214, 0.2);
}

.textEnd {
    text-align: end !important;
}

.pos {
    position: absolute;
    width: 1.302vw;
    height: 1.302vw;
    transform-style: preserve-3d;
    animation-name: invert;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    transition-property: top, left;
    transition-duration: 0.8s;
    left: 97%;
    top: 50%;
    border-radius: 50%;
    z-index: 99;
    pointer-events: auto;
}

.metaMakerText {
    width: 95%;
    bottom: 1px !important;
    padding: 1px;
    cursor: pointer;
    height: 8px;
    z-index: 99;
}

@keyframes orbit {
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(-360deg);
    }
}

@keyframes suborbit {
    0% {
        transform: rotateX(90deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(90deg) rotateZ(-360deg);
    }
}

@keyframes invert {
    0% {
        transform: rotateX(-90deg) rotateY(360deg) rotateZ(0deg);
    }

    100% {
        transform: rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
    }
}

dl.infos {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 1;
}

dl.infos:hover {
    box-shadow: rgba(157, 203, 242, 1);
}

dl.infos::before {
    position: absolute;
    content: '';
    width: 36px;
    height: 71px;
    right: 67px;
    top: -12px;
    border-top: 1px solid rgba(74, 149, 214, 0.75);
    border-right: 1px solid rgba(74, 149, 214, 0.75);
    transform-style: preserve-3d;
    transform: rotate(-45deg) skew(45deg, 0deg);
}

.hide_orbit_line.infos::before {
    content: none;
}

.eva-plus-circle-icon-wrapper .shadow {
    position: absolute;
    top: 3px;
    left: 5px;
    box-shadow: 0 0 27px 3px #9dcbf2;
    width: 16px;
    height: 16px;
    z-index: -1;
    border-radius: 50%;
}

dl.infos dt {
    position: absolute;
    right: 50px;
    top: 35px;
    z-index: -1;
    min-width: 160px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #ffffffbf;
}

dl.infos dt span {
    font-weight: 500;
    color: #ffffff;
}

/* slick slider */
.eva-first-slider-wrapper {
    width: 80%;
    height: 75%;
    margin: auto;
    z-index: -999;
}

.eva-first-slider {
    outline: none;
    position: relative;
}

.enter-btn-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 999;
}

.eva-first-slider-heading {
    margin-top: 4.2rem;
}

.eva-first-slider-planet {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 7px;
}

.eva-first-slider-planet .submit_btn {
    visibility: hidden;
    position: absolute;
}

.eva-switch-btn {
    margin-left: 32%;
    position: absolute;
    bottom: 80px;
}

.eva-second-slider-circle {
    width: 0.6em;
    height: 0.6em;
    fill: #4a95d6;
    right: 5px;
    position: absolute;
    top: 5px;
}

.eva-orbit-space {
    height: 624px;
    width: 924px;
    margin: 24px 0 0;
}

.eva_bottom_content {
    width: 100%;
    position: relative;
}

.publish-eva-dynamic-text {
    height: 40px;
    max-width: 890px;
    width: 100%;
    overflow-y: auto;
    margin: auto;
}

.publish-eva-dynamic-text p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
}

@media (max-width: 1600px) {
    .publish-eva-dynamic-text {
        max-height: 88px;
        max-width: 600px;
        overflow-y: auto;
    }

    .eva_bottom_content {
        margin-bottom: 20px;
    }
}

@media (max-width: 1400px) {
    .publish-eva-dynamic-text {
        max-height: 88px;
        max-width: 40% !important;
        overflow-y: auto;
    }

    .eva-footer {
        font-size: 10px !important;
    }

    .eva-footer {
        font-size: 10px !important;
    }
}

.bg-dark-blue-radial .slick-dots {
    display: none !important;
}

@media (max-width: 991px) {
    .eva-footer {
        font-size: 12px !important;
    }
}

.eva-orbit-seperator {
    border-color: #e5e7eb;
}

.eva-publish-planet-labels {
    border-radius: 7px;
    width: 64px;
    height: 31px;
    padding: 0;
    border: 0;
    outline: 1px solid rgba(15, 16, 68, 0.1);
    display: flex;
    justify-content: space-evenly;
}

.eva-publish-planet-labels p {
    margin-left: 0 !important;
    font-family: Poppins;
    font-weight: 400;
    line-height: 15px;
}

.eva-publish-planet-bottom-div {
    gap: 8px;
}

.eva-popover-main-div {
    z-index: 9999;
    background: none;
}

.eva-popover-icon {
    background: #4a95d6;
    color: #0f1044;
}

.eva-popup-flag {
    background: rgba(74, 149, 214, 0.05);
}

.eva-trigger-text {
    font-size: 10px;
    color: #4a95d6;
}

.eva-amount-input {
    position: relative;
    background-color: #4a95d61a;
    border: 1px solid;
    font-size: 12px;
    border-color: transparent;
    padding: 8px;
    border-radius: 20px;
}

.eva-nuances-input {
    background-color: transparent;
    width: 80%;
}

.eva-plus-circle-icon {
    position: absolute;
    fill: #4a95d6;
    cursor: pointer;
    top: 50%;
    right: 5px;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
}

.eva-plus-circle-icon-wrapper {
    height: 25px;
    width: 25px;
    position: relative;
    top: -1px;
    right: 1px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 99;
}

.eva-plus-circle-icon-wrapper svg {
    transform: translate(4px, 1px);
    position: absolute;
    right: -9px;
}

.slick-list {
    overflow: visible !important;
    height: 100%;
}

.slick-track {
    height: 100%;
}

.screen_slick_wrapper .slick-dots li button::before {
    display: none;
    font-family: 'slick';
    font-size: 0;
    line-height: 20px;
    position: absolute;
    top: 0;
    background-color: red;
    left: 0;
    width: 16px;
    height: 6px;
    content: '';
    text-align: center;
    opacity: 1;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.screen_slick_wrapper .slick-dots li button {
    padding: 0;
    background-color: rgba(74, 149, 214, 0.1);
    width: 6px;
    height: 16px;
    border-radius: 20px;
}

.screen_slick_wrapper .slick-dots {
    display: inline-block;
    width: auto;
    left: 10%;
    top: 60%;
    transform: translateY(-50%);
}

.screen_slick_wrapper .slick-dots .slick-active button {
    height: 32px;
    background-color: rgba(74, 149, 214, 0.5);
}

.screen_slick_wrapper .slick-dots li {
    display: block;
    width: auto;
    height: auto;
    margin: 5px 0;
}

.custom-input {
    background-color: transparent;
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    font-style: italic;
}

.custom-input.meta-input {
    font-size: 14px;
}

.custom-input::placeholder {
    font-size: 12px;
    color: rgba(74, 149, 214, 0.5);
}

/* Eva Graph */
.eva-graph-xaxis-text {
    position: absolute;
    top: 50%;
    transform: rotate(270deg);
}

.eva-graph-yaxis-text {
    position: absolute;
    bottom: 5%;
    left: 50%;
}

.eva-graph-tooltip {
    width: auto;
    height: auto;
    background: rgb(0, 183, 255);
    font-size: 11px;
    max-width: 400px;
    padding: 5px 3px;
    position: absolute;
    margin-left: 30px;
    transform: translateY(-50%);
    border-radius: 1px;
}

/* Vertical line styling */
.absolute-flag-line {
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #ebe8ff;
    left: 50%;
    transform: translateX(-50%);
}

.publish-planet-log {
    max-width: fit-content !important;
    min-width: 455px;
}

.numInput {
    position: relative;
    width: 50px;
}

.numInput-border {
    border-radius: 50px;
    width: 58px;
    margin-left: 8px;
    border: 1px solid rgba(74, 149, 214, 0.25);
    background: rgba(74, 149, 214, 0.1);
}

.number {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    border-radius: 2px;
    height: 100%;
    max-width: 40px;
    background: transparent;
}

.number:focus {
    outline: 0;
    box-shadow: none;
}

input[type='number']::-webkit-inner-spin-button {
    opacity: 1;
    display: none;
}

.inputControl {
    color: inherit;
    position: absolute;
    display: flex;
    align-items: center;
    right: 2px;
    top: 50%;
    transform: translateY(-50%);
}

.buttonWrapper {
    display: flex;
    width: fit-content;
    flex-direction: column;
    margin-right: 8px;
}

.stepper {
    width: 0;
    height: 0;
    border-style: solid;
    cursor: pointer;
}

.stepperUp {
    border-width: 0 5px 5px;
    border-color: transparent transparent rgb(74, 149, 214) transparent;
    margin-bottom: 5px;
}

.stepperUp:hover,
.stepperUp.stepperDisabled:hover {
    border-color: transparent transparent rgb(74, 149, 214) transparent;
}

.stepperDown {
    border-width: 5px 5px 0;
    border-color: rgb(74, 149, 214) transparent transparent transparent;
}

.stepperDown:hover,
.stepperDown.stepperDisabled:hover {
    border-color: rgb(74, 149, 214) transparent transparent transparent;
}

.stepperDisabled {
    border-color: rgba(74, 149, 214, 0.5) transparent rgba(74, 149, 214, 0.5) transparent !important;
}

.MuiPaper-root {
    background-color: transparent !important;
    color: rgba(74, 149, 214, 1) !important;
}

.eva-test-btn {
    width: 96px;
    height: 32px;
    border-radius: 7px;
    border: 1px solid #6fbfe140;
    background: #6fbfe11a;
    font-family: Poppins;
    font-size: 12px;
    display: flex;
    justify-content: center;
    font-weight: 400;
    align-items: center;
    color: #6fbfe1bf;
    padding: 0;
}

.eva-test-btn:hover {
    border: 1px solid #6fbfe180;
    color: #6fbfe1;
    background-color: #6fbfe11a !important;
}

.eva-test-btn-active {
    border: 1px solid #60f4c840 !important;
    background: #60f4c81a !important;
    color: #60f4c8bf;
}

.eva-test-btn-active:hover {
    background: #60f4c833 !important;
    border: 1px solid #60f4c880 !important;
    color: #60f4c8 !important;
}

.eva-test-eye-icon-active {
    background: rgba(96, 244, 200, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.667vw;
    border-radius: 4px;
    height: 1.667vw;
    margin: 7px 0;
}

.eva-test-eye-icon {
    background: #6fbfe1bf !important;
    border-radius: 7px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gredient_line {
    position: absolute;
    right: -150px;
    top: 50%;
    transform: translateY(-50%);
}

.gredient_line_box {
    border-right: 1px solid;
}

.gredient_line_box p {
    margin-bottom: 40px;
    position: relative;
    /* padding-right: 24px; */
}

.gredient_line_box p::before {
    content: '';
    right: 0;
    position: absolute;
    width: 9px;
    height: 2px;
    background: #4a95d6;
    top: 11px;
    margin-right: 0;
}

.opacity-85 {
    opacity: 0.85;
}

.opacity-65 {
    opacity: 0.65;
}

.opacity-55 {
    opacity: 0.55;
}

.marker_blue_box,
.trigger_shape {
    position: relative;
}

.trigger_shape::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-left: 8px solid #4a95d6;
    border-bottom: 8px solid transparent;
    top: 26px;
    left: 0;
}

.marker_blue_box_shape {
    clip-path: polygon(0 0, 92% 0, 100% 9%, 100% 80%, 100% 100%, 0 100%, 0% 80%, 0% 20%);
    width: 210px;
}

.copyright-text p {
    font-size: 10px;
}

.copyright-text p a,
.copyright-text p {
    color: #c3c3ce;
}

.copyright-text p a {
    text-decoration-color: rgba(255, 255, 255, 0.75);
}

.copyright-text p a:hover {
    text-decoration-color: rgba(255, 255, 255, 0.75);
}

input:focus {
    background-color: transparent;
    border: transparent;
    outline: none;
}

.nuance-input {
    width: 45px;
}

.nuance-input .inputControl {
    right: 0;
}

.graph-button button {
    background: #fff;
    font-size: 12px;
    border-color: rgba(15, 16, 68, 0.09);
    color: rgba(15, 16, 68, 0.75);
}

.graph-button .graph-select button {
    background: #f4f4f5;
    border-radius: 5px;
    font-size: 12px;
    border-color: rgba(15, 16, 68, 0.09);
    color: rgba(15, 16, 68, 0.75);
    line-height: 1;
    padding: 0 40px 0px 16px;
}

.marker_blue_box_shape .disable-nuance {
    color: #3e5796;
}

.graph-log-row {
    max-width: 424px;
    width: 100%;
    height: 200px;
    bottom: -7px;
    left: 12px;
}

.graph-log {
    display: flex;
    gap: 8px;
    margin: 8px 0;
    flex-direction: column-reverse;
    max-height: 140px;
    position: relative;
    z-index: 1;
    overflow-y: auto;
}

.graph_buttons .tooltip_box {
    background: #0014ff;
    position: absolute;
    width: auto;
    bottom: 130%;
    padding: 5px;
    top: 27px;
    height: max-content;
    right: 0;
    transform: translateX(-70%);
    border-radius: 7px;
    display: none;
}

.graph_buttons .tooltip_box p {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    margin: 3px;
    text-align: left;
    color: #fff;
}

.graph_buttons .eva_update_user_img:hover .tooltip_box {
    display: block;
}

.graph_buttons .eva_update_user_img {
    top: 12px;
    right: -12px;
    z-index: 8;
    cursor: pointer;
}

.graph_buttons .eva_update_user_img img {
    width: 25px;
    height: 25px;
    background-color: #fff;
    border-radius: 50%;
    object-fit: cover;
}

.graph-log::-webkit-scrollbar {
    display: none;
}

.graph-log:hover::-webkit-scrollbar {
    display: block;
}

.graph-log .record {
    width: 100%;
    height: 16px;
    margin: auto;
    padding: 2.5px 6px 2.5px 4px;
}

.graph-log .record p {
    color: rgba(111, 191, 225, 0.75);
    font-family: Courier Prime;
    font-size: 10px;
    display: flex;
    gap: 10px;
    font-weight: 400;
    line-height: 11.23px;
}

.graph-log .record .name {
    width: 225px;
    overflow: hidden;
}

.graph-log .record .name:hover .scroll_name {
    animation: scroll_name 5s linear infinite;
}

@keyframes scroll_name {
    0% {
        transform: translateX(0%);
    }

    50% {
        transform: translateX(-30%);
    }

    100% {
        transform: translateX(5%);
    }
}

.graph-log .record.heighLight {
    background: rgba(111, 191, 225, 0.75);
}

.graph-log .record.heighLight .bg-sky-blue {
    background-color: rgba(255, 255, 255, 1);
}

.graph-log .record.heighLight p {
    color: rgba(255, 255, 255, 1) !important;
}

.graph_top {
    position: absolute;
    right: 20px;
    z-index: 9;
    top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.graph-log-row .content_box {
    width: 100%;
    margin: 10px 0;
    height: 180px;
    padding: 5px 10px;
    position: relative;
}

.graph-log-row:hover .content_box {
    background: rgba(111, 191, 225, 0.072);
    cursor: pointer;
}

.graph_top .button_box {
    display: flex;
    gap: 15px;
    align-items: center;
}
.graph_top .button_box .orbit_eva {
    width: 32px;
    height: 32px;
    border-radius: 4px;
}
.graph_top .button_box .orbit_eva:hover {
    background: rgba(15, 16, 68, 0.05);
}
.graph_top.state_of_entities {
    right: 10px !important;
    left: unset;
    width: max-content;
    z-index: 99;
    gap: 20px;
    padding: 0 5px 0 10px;
}
.button_box .no_eva_image {
    opacity: 0.7;
}

.graph_top .button_box .orbit_eva .eva_info_box {
    position: absolute;
    width: 322px;
    min-height: 88px;
    padding: 8px;
    top: 40px;
    right: -100px;
    border-radius: 7px;
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px #0f10441a;
}

.graph_top .button_box .orbit_eva .eva_info_box h2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    color: #0f1044bf;
}

.graph_top .button_box .orbit_eva .eva_info_box p {
    font-family: Poppins;
    font-size: 10px;
    margin-top: 3px;
    font-weight: 400;
    word-break: break-all;
    color: #0f104480;
}

.content_box .top_box {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 9;
    justify-content: space-between;
}

.is_testingBox {
    width: 150px;
    margin-left: auto;
    overflow: hidden;
    display: flex;
}

.is_testingBox p {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    width: fit-content;
    margin-left: auto;
    text-align: left;
    color: rgba(15, 16, 68, 0.5);
    position: relative;
    animation-name: fade_in;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 2s;
    cursor: pointer;
    animation-direction: alternate-reverse;
    margin-right: -51px;
    transition: 0.8s all;
}

.scroll_text {
    margin-right: 0 !important;
    transition: 0.8s all;
}

@keyframes fade_in {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.is_testingBox p::before {
    content: '';
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background: linear-gradient(134.19deg, #4a95d6 -8.45%, #bc54ff 70.7%, #f14f98 138.76%);
}

.is_testingBox .live {
    animation: none !important;
}

.is_testingBox .live::before {
    background: #4abc90 !important;
}

.graph_top .update_btn {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    color: #fff;
    width: 97px;
    height: 40px;
    background: rgba(0, 20, 255, 1);

    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
}
.graph_top .update_btn svg path {
    fill: #fff;
    fill-opacity: 1;
}

.graph_top .update_btn span {
    display: flex;
    gap: 10px;
}
.graph_top .update_btn:hover {
    background: rgba(0, 20, 255, 1);
}

.graph_top .button_box .orbit_eva {
    width: 32px;
    height: 32px;
    border-radius: 4px;
}

.graph_top .button_box .orbit_eva:hover {
    background: rgba(15, 16, 68, 0.05);
}

.graph_top .btn_wrapper:hover {
    border-radius: 5px;
    box-shadow: 0px 3px 6px 0px #a95ff826;
}

.text-nowrap {
    text-wrap: nowrap;
}

.graph-log:hover {
    overflow-y: auto;
}

.blue-print-title-box {
    box-shadow: 0px 4px 6px rgba(15, 16, 68, 0.16);
    border-radius: 7px;
    max-width: 415px;
}

.graph-log::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

.web_visit {
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 9;
    min-width: 176px;
    height: 32px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
}

.web_visit_is_shield {
    padding: 0 10px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.web_visit_is_shield .main_star_button .onHover_msg::before {
    content: none;
}

.web_visit_is_shield .main_star_button .onHover_msg {
    top: 23px;
}

.web_visit_is_shield .empty_img {
    width: 130px;
    margin-right: auto;
}

.web_visit_is_shield.border_hover .corner1 {
    border-color: #0f10442b;
}

.web_visit_is_shield.border_hover .corner {
    border-color: #0f10442b !important;
}

.web_visit_is_shield:hover {
    background: #0f104405 !important;
}

.web_visit .visit_count {
    display: flex;
    gap: 5px;
    align-items: center;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: #6fbfe1;
}

.main_protocol_group {
    left: 12px;
    top: 65px;
    position: absolute;
    z-index: 9;
    width: 410px;
}

.top_protocol {
    width: 40px;
    margin-bottom: 10px;
}

.show_protocol_list .top_protocol {
    width: 100%;
}

.main_protocol_group .protocol_box {
    max-height: 46vh;
}

.main_protocol_group .protocol_input_box {
    border-bottom: 1px solid #0f10441a;
}

.blue_print_eva {
    z-index: 1;
}

.eva_top_head {
    margin-top: 0 !important;
    max-width: 48.073vw;
    width: 100%;
    height: auto;
    padding: 10px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 7px;
    background-color: rgba(111, 191, 225, 0.1);
}

.eva_top_head .eva_content {
    max-width: 28.646vw;
    width: 100%;
    margin: auto;
}

.eva_top_head .eva_content input {
    font-size: 12px !important;
}

.eva_top_head .eva_content .eva_top_title input,
.eva_top_head .eva_content .eva_top_title p {
    font-family: Poppins;
    font-size: 0.625vw !important;
    font-weight: 500;
    line-height: 0.938vw;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    font-style: italic;
}

.eva_top_head .eva_content .eva_top_disc input,
.eva_top_head .eva_content .eva_top_disc p {
    font-family: Poppins;
    font-size: 0.521vw !important;
    font-weight: 400;
    line-height: 0.781vw;
    text-align: center;
    color: rgba(255, 255, 255, 1);
    font-style: italic;
}

.eva_top_disc .description_input_box {
    max-width: 400px;
    width: 100%;
}

.eva_top_disc .description_input_box > div {
    width: 100%;
}

.eva_top_disc .description_input_box input {
    width: 100%;
    margin-top: 15px;
}

.eva_top_head .eva_content .text-error {
    color: rgba(255, 255, 255, 1);
}

.blue_print_eva .main_protocol_group .protocol_input_box {
    border-color: rgba(255, 255, 255, 0.1);
}

.main_protocol_group .no_data {
    font-size: 11px;
    text-align: center;
}

.search_val {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    width: 300px !important;
}

.outside_eva .search_va {
    border-bottom: 1px solid #0000002d;
}

.search_val input {
    opacity: 1 !important;
}

.main_protocol_group .content img {
    margin-top: 5px;
    z-index: 9;
}

.protocol_input_box input:focus::placeholder {
    opacity: 0 !important;
}

.protocol_input_box {
    position: relative;
    width: 100%;
}

.main_protocol_group .protocol_input_box input {
    width: 100%;
    border: 0;
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    padding: 7px 0;
    line-height: 15px;
    background-color: transparent;
}

.protocol_box {
    padding-right: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

.main_protocol_group .protocol_box::-webkit-scrollbar {
    display: none;
}

.show_protocol_list .protocol_box::-webkit-scrollbar {
    display: block;
    width: 5px;
}

.main_protocol_group .protocol_box::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: #0f10440d;
}

.protocol_box .protocol_count:hover .tooltip_box {
    display: flex;
    width: 57px;
    height: 32px !;
    left: 50%;
    background: rgba(0, 20, 255, 0.75);
    transform: translateX(-50%);
}

.blue_print_eva .main_protocol_group .protocol_box::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.05);
}

.blue_print_eva .main_protocol_group .protocol_box::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
}

.main_protocol_group .protocol_box::-webkit-scrollbar-track {
    border-radius: 7px;
    background: #0f10440d;
}

.main_protocol_group .protocol_box .main_protocol_content {
    display: flex;
    align-items: center;
}

.protocol_box .main_protocol_content .protocol_count {
    display: none;
    margin-left: 20px;
    gap: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: linear-gradient(58.31deg, #0014ff 4.58%, #75ece2 109.35%);
}

.protocol_box .main_protocol_content:hover .protocol_count {
    display: flex;
}

.protocol_box .main_protocol_content.active_protocol .protocol_count {
    display: flex;
}

.main_protocol_group .protocol_box .main_protocol_content .protocol_count div {
    min-width: 30px;
    height: 24px;
}

.main_protocol_group .protocol_box .main_protocol_content .protocol_count span {
    color: #fff;
    font-family: Poppins;
    font-size: 10px;
    display: flex;
    font-weight: 500;
    gap: 5px;
    line-height: 15px;
    position: relative;
    z-index: 9;
}

.main_protocol_content:hover .content span {
    color: #0f1044bf;
}

.protocol_box .main_protocol_content.active_protocol .content span {
    color: #0f1044bf;
}

.main_protocol_group .protocol_box .main_protocol_content .content {
    width: 345px;
    height: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    gap: 5px;
    padding: 0 7px;
    cursor: pointer;
    position: relative;
    border-radius: 3px;
    transition: 0.3s all;
}

.main_protocol_group .protocol_box .main_protocol_content .content::before {
    position: absolute;
    content: '';
    left: 0;
    height: 24px;
    width: 31px;
    box-shadow: 0px 2px 6px 0px #0f10441a;
    background-color: #fff;
    top: 0;
}

.show_protocol_list .protocol_box .main_protocol_content .content::before {
    transition: none;
    width: 31px;
}

.main_protocol_group .open_btn svg {
    scale: 1.2;
}

.main_protocol_group .open_btn {
    min-width: 31px;
    cursor: pointer;
    border-radius: 5px;
}

.blue_print_eva .main_protocol_group .open_btn svg path {
    fill: #fff !important;
}

.main_protocol_group .open_btn:hover {
    background: rgba(15, 16, 68, 0.05);
}

.show_protocol_list .open_btn svg {
    transform: rotate(180deg);
}

.main_protocol_group .protocol_box .main_protocol_content .content p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
    font-family: Poppins;
    font-size: 10px;
    z-index: 9;
    font-weight: 500;
    color: rgba(15, 16, 68, 0.5);
    line-height: 15px;
}

.main_outside_eva .main_protocol_group .protocol_box .main_protocol_content:hover .content p {
    color: rgba(15, 16, 68, 0.75);
}

.blue_print_eva .main_protocol_group .protocol_box .main_protocol_content .content p {
    color: rgba(255, 255, 255, 0.5);
}

.blue_print_eva .main_protocol_group .protocol_box .main_protocol_content:hover .content p {
    color: rgba(255, 255, 255, 0.75);
}

.main_protocol_group .protocol_box .main_protocol_content .content p {
    display: none;
}

.show_protocol_list .protocol_box .main_protocol_content .content p {
    display: flex;
}

.main_protocol_group .protocol_box .main_protocol_content:hover .content p {
    display: block;
}

.main_protocol_group .protocol_box .main_protocol_content:hover .content::before {
    width: 345px;
    opacity: 1;
    transition: 0.5s all;
}

.main_protocol_group .protocol_box .main_protocol_content.active_protocol .content p {
    display: block;
}

.main_protocol_group .protocol_box .main_protocol_content.active_protocol .content::before {
    width: 345px;
    opacity: 1;
    transition: 0.5s all;
}

.show_protocol_list .protocol_box .main_protocol_content .content::before {
    width: 345px;
    transition: none;
}

.main_protocol_group .protocol_box .content .side_bg {
    position: absolute;
    width: 2px;
    height: 100%;
    left: 0;
}

.web_visit:hover {
    background: #6fbfe112;
}

.blue-print-title-box p {
    color: rgba(15, 16, 68, 0.75);
}

.graph-log::-webkit-scrollbar {
    width: 4px;
    display: none;
}

.graph-log:hover::-webkit-scrollbar {
    display: block;
}

.graph-log::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: rgba(111, 191, 225, 0.75);
}

.graph-log::-webkit-scrollbar-track {
    background-color: rgba(111, 191, 225, 0.41);
    border-radius: 7px;
}

.marker_blue_box_content {
    padding-top: 0;
    margin-right: 7px;
    margin-top: 15px;
    min-height: 115px;
    overflow-y: auto;
    max-height: 140px;
}

.marker_blue_box_content::-webkit-scrollbar {
    width: 3px;
}

.marker_blue_box_content::-webkit-scrollbar-thumb {
    background-color: #336199;
    border-radius: 5px;
}

.marker_blue_box_content::-webkit-scrollbar-track {
    background-color: #4a95d6;
    border-radius: 5px;
}

.metaBox {
    overflow-y: auto;
    height: 108px;
}

.metaBox::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

.metaBox::-webkit-scrollbar-thumb {
    background-color: #676ba5;
    border-radius: 5px;
}

.metaBox::-webkit-scrollbar-track {
    background-color: #7578a4;
    border-radius: 5px;
}

.published-eva-organization-logo img {
    object-position: center;
    object-fit: contain;
}

/* ====== First Slider ====== */

.eva-listing-slider {
    width: 924px;
    height: calc(93vh - 119px) !important;
}

@media (max-width: 769px) {
    .eva-listing-slider {
        height: calc(93vh - 73px) !important;
    }
}

@media (max-width: 991px) {
    .eva-listing-slider {
        width: fit-content;
        margin: 0 10px;
    }
}

.h-inherit {
    height: 70vh !important;
}

.eva-listing {
    background: rgba(255, 255, 255, 0.05);
}

.icon-box {
    border-radius: 5px;
    line-height: 40px;
    color: #fff;
    width: 24px;
    height: 24px;
    background: linear-gradient(180deg, rgba(74, 149, 214, 0.15) 42.5%, rgba(74, 149, 214, 0.054) 100%);
    box-shadow: 0px 1px 4px rgba(19, 145, 255, 0.16);
    border: 1px solid rgba(74, 149, 214, 0.15);
}

.dots.icon-box::after {
    position: absolute;
    content: '';
    width: 2px;
    height: 2px;
    background-color: #fff;
    border-radius: 50%;
}

.details-blue-box .protocol_text {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.75);
}

.details-blue-box .protocols {
    font-weight: 500 !important;
}

.template_eva_heading {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
}

.template_eva_sub_heading {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
}

.main_modal {
    z-index: 999 !important;
}

.main_modal,
.main_modal .MuiBackdrop-root {
    top: 129px !important;
    width: calc(100% - 47px);
    margin: 0 auto;
    overflow: hidden;
    border-radius: 10px;
    height: calc(100% - 114px);
    top: 65px !important;
}

.MuiDrawer-modal {
    height: calc(100% - 185px);
    left: 130px;
}

.MuiDialog-paperFullScreen {
    border-radius: 10px;
}

.blue_print_eva .main_protocol_group .protocol_box .content {
    cursor: pointer;
}

.blue_print_eva .main_protocol_group .protocol_box .main_protocol_content .content::before {
    background: #ffffff1a;
}

.details-blue-box .blue_top_heading {
    font-family: Poppins;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
}

.details-blue-box .blue_top_detail {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
}

.eva-listing hr {
    border-color: rgba(255, 255, 255, 0.15);
}

.eva-listing {
    height: 70vh;
}

.eva-listing h3 {
    color: rgba(255, 255, 255, 0.5);
}

.eva-listing .list_scrolling {
    height: calc(100% - 100px);
    overflow-y: auto;
}

.template_text {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
}

.eva-listing-tab li {
    color: #c7c7d2;
    display: flex;
    align-items: center;
    gap: 10px;
}

.eva-listing-tab li img {
    margin-left: 10px;
}

.eva-listing-tab li span.disabled {
    color: rgba(255, 255, 255, 0.35) !important;
    pointer-events: none !important;
    padding-left: 0;
    width: fit-content;
}

.eva-listing-tab li span {
    width: 100%;
    height: 100%;
    display: block;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
}

.start_blank p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
}

.kickstart {
    display: flex;
    align-items: center;
    gap: 8px;
}

.kickstart p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: rgba(255, 255, 255, 0.75);
}

.kickstart p.disabled {
    color: rgba(255, 255, 255, 0.35) !important;
}

.eva-listing-tab li.active,
.eva-listing-tab li:hover {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 1);
}

.eva-listing-details .details-blue-box {
    border-radius: 7px;
    background: #11113f;
    border-color: rgba(255, 255, 255, 0.07);
}

.eva-listing-details::-webkit-scrollbar,
.eva-listing .list_scrolling::-webkit-scrollbar,
.chart-tooltip-modal::-webkit-scrollbar,
.scroll-thin::-webkit-scrollbar,
.eva-listing-tab::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.eva-listing-details::-webkit-scrollbar-thumb,
.scroll-thin::-webkit-scrollbar-thumb,
.eva-listing .list_scrolling::-webkit-scrollbar-thumb,
.eva-listing-tab::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}

.eva-listing-details::-webkit-scrollbar-track,
.eva-listing .list_scrolling::-webkit-scrollbar-track,
.scroll-thin::-webkit-scrollbar-track,
.eva-listing-tab::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 5px;
}

.chart-tooltip-modal::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.35);
    border-radius: 5px;
}

.chart-tooltip-modal::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
}

#custom-tooltip {
    position: absolute;
    background-color: rgba(15, 16, 68, 0.75);
    border-radius: 8px;
    color: #fff;
    overflow: hidden;
    font-size: 10px;
    font-family: 'Poppins';
}

.chart-tooltip-modal {
    max-height: 166px;
    max-width: 154px;
    overflow-y: auto;
    padding-right: 10px;
}

.publish-planet-log {
    max-width: fit-content !important;
    min-width: 455px;
}

.slick-track .slick-slide:first-child > div {
    height: 100%;
}

@media screen and (max-width: 1366px) {
    .publish-eva-dynamic-text {
        max-width: 50%;
    }
}

@media screen and (max-width: 768px) and (max-height: 800px) {
    .eva-switch-btn {
        bottom: 50px;
        margin-left: 23%;
    }

    .heading-content {
        width: 90%;
    }
}

@media screen and (min-height: 800px) and (max-height: 900px) {
    .main_protocol_group .protocol_box {
        max-height: 43vh;
    }
}

@media screen and (max-height: 768px) {
    .gredient_line_box p {
        margin-bottom: 20px;
    }

    .eva-first-slider-heading {
        margin-top: 0.5rem;
    }
}

.plans-rectangle {
    height: 650px;
    background-color: rgba(77, 120, 255, 0.05);
    z-index: 1;
    margin-top: 1.6rem;
}

.marginTopplan {
    margin-top: 25px !important;
}

.margintopIsPro {
    margin-top: 16px !important;
}

.heightPlan {
    height: 37px;
}

.multi-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Limit to two lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.truncate {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
}

.archive-btn:hover .icon {
    fill-opacity: 1;
}

.archive-btn.disable:hover {
    fill-opacity: 0;
}

.text-fill-transparent {
    -webkit-text-fill-color: transparent;
}

.published-eva-header-button {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    top: -55px;
}

.eva-graph-log-test-btn {
    left: 15px;
    top: 15px;
}

.gradient-ring {
    width: 17px;
    height: 17px;
    filter: drop-shadow(0px 0px 5px rgba(96, 191, 244, 0.5));
    background-image: linear-gradient(180deg, #5a44ff 0%, rgba(96, 191, 244, 0.5) 100%);
    border-radius: 50%;
}

.gradient-ring::after {
    position: absolute;
    content: '';
    border-radius: 50%;
    width: 13px;
    height: 13px;
    background-color: #fff;
    top: 2px;
    left: 2px;
}

.text-animation-load {
    height: 38px;
    margin-bottom: 50px;
}

.text-animation-load .gradient1 {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #000000;
    background: linear-gradient(to right, #f1f8fc 8%, #aca1ff 16%, #afdef9 50%, #f1f8fc 8%);
    background-size: 1000px 640px;
    position: relative;
    height: 12px;
    width: 400px;
    left: 16px;
    border-radius: 50px;
}

.text-animation-load .gradient2 {
    animation-duration: 1s;
    animation-delay: 0.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #000000;
    background: linear-gradient(to right, #f1f8fc 8%, #aca1ff 16%, #afdef9 50%, #f1f8fc 8%);
    background-size: 1000px 640px;
    position: relative;
    top: 10px;
    left: 16px;
    height: 12px;
    width: 400px;
    border-radius: 50px;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}

.user-dropdown {
    transform: translateY(-50%);
    right: 26px !important;
}

.user-dropdown .profile_img {
    margin-left: 2px !important;
}

.show_dropdown {
    top: 42px;
}

.bg-eva-publish {
    background: linear-gradient(to right, #4a95d6 0%, #bc54ff 50%, #f14f98 100%);
}

.top-100 {
    top: -100px !important;
    left: 20px !important;
}

.left-100 {
    left: -130px !important;
    top: -100px !important;
}

.input-max-length {
    left: 11px;
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
    color: rgba(111, 191, 225, 0.75);
}

.input-max-length {
    left: 11px;
    font-size: 10px;
    line-height: 15px;
    font-weight: 400;
    z-index: 9;
    color: rgba(111, 191, 225, 0.75);
}

.input-text {
    max-width: 290px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    border-radius: 5px;
    background: url(/src/assets/icons/input_border.png);
    background-size: 99% !important;
    background-position: 100%;
    background-repeat: no-repeat;
}

.input_box .border_bg {
    padding: 1px;
    border-radius: 5px;
}

.input-text input {
    background-clip: text;
    caret-color: rgba(74, 149, 214, 0.5);
    box-sizing: border-box;
    background-size: 100% 100%;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    background-color: transparent;
    background-image: linear-gradient(
        90deg,
        rgb(74, 149, 214) 0.12%,
        rgb(188, 84, 255) 34.64%,
        rgb(241, 79, 152) 64.31%
    ) !important;
    color: transparent !important;
    padding: 0px 15px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
}

.input-text input::placeholder {
    color: #4a95d680;
}

.input-length {
    top: -7px;
    right: 10px;
    margin-right: 25px;
    width: 34px;
    min-height: 16px;
    margin-right: 25px;
    width: 34px;
}

.input-length-border {
    width: 33px;
    border: 1px solid rgb(34 50 95);
    z-index: 9;
}

@media (max-width: 768px) {
    .orbit_box {
        height: 100vh;
    }

    .orbit_box > div {
        margin-bottom: 0;
    }
}

.oversight-button {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 7px 0px rgba(0, 20, 255, 0.15);
    border-radius: 10px;
    color: rgba(15, 16, 68, 0.75);
}

/* switch_btn css  */
.switch_btn {
    position: absolute;
    right: 10px;
    display: flex;
    bottom: 10px;
    width: 48px;
    border-radius: 7px;
    box-shadow: 0px 2px 4px 0px rgba(15, 16, 68, 0.12);
    background: rgba(15, 16, 68, 0.03);
    height: 48px;
    align-items: center;
    justify-content: center;
}

.switchOn_btn .switchOnHover {
    display: none;
}

.switchOn_btn:hover .switchOnHover {
    display: block;
}

.switchOn_btn:hover .switchOn {
    display: none !important;
}

.switch_btn h1 {
    font-family: Poppins;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    min-width: 50px;
    height: 15px;
}

.outer_img svg {
    scale: 0.95;
}

.eva_circle {
    width: 100vw;
    height: 87vh;
}

.outer_img img {
    width: 169px;
    height: 169px;
    scale: 1.22;
    border-radius: 50%;
}

.tab_link {
    position: relative;
    z-index: 99;
}

.tab_link > div {
    width: fit-content;
    height: -webkit-fill-available;
}

.tab_link > div > div {
    justify-content: space-between;
    gap: 10px;
    height: -webkit-fill-available;
    align-items: center;
}

.eva_before_content {
    max-width: 890px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    min-height: 40px;
    margin-bottom: 25px;
}

.eva_before_content p {
    font-family: Poppins;
    font-weight: 400;
    line-height: 18px;
    width: 100%;
    text-align: center;
}

/* orbit style and animation  */

.outset_loader {
    width: 100%;
    margin: 10px;
    margin-right: 20px;
    height: 97%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.star_icon .img2 {
    display: none;
}

.star_icon:hover .img1 {
    display: none;
}

.star_icon:hover .img2 {
    display: block;
}

.star_icon:hover .img2 {
    width: 16px;
    scale: 1.5;
    height: 16px;
}

/* shield star button on hover css  */

.main_star_button {
    position: relative;
}

.main_star_button .onHover_msg {
    text-align: center;
    position: absolute;
    width: 148px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 8px;
    background: rgba(0, 20, 255, 1) !important;
    border-radius: 7px;
    color: rgba(255, 255, 255, 1);
    left: 50%;
    z-index: 99999;
    top: 30px;
    display: none;
    transform: translateX(-50%);
}

.main_star_button .onHover_msg span {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    display: block;
    line-height: 15px;
}

.main_star_button:hover .onHover_msg {
    display: block;
}

.orbit_div .onHover_msg {
    transform: rotate(-90deg);
    top: -8px;
    left: -34px;
    color: rgba(255, 255, 255, 1);
}

.orbit_div .star_icon .img2 {
    transform: rotate(-90deg);
}

.eva-listing-tab .main_star_button:hover .onHover_msg {
    top: 20px;
    left: 65%;
    z-index: 9999;
}

.kickstart .onHover_msg {
    top: 25px;
}

.details-blue-box {
    position: relative;
}

.details-blue-box .category_star {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 70%;
    z-index: 9;
}

.disable.details-blue-box .blue_top_detail {
    color: rgba(255, 255, 255, 0.35);
}

.disable.details-blue-box .blue_top_detail img {
    opacity: 0.5;
}

.disable.details-blue-box {
    max-height: 169px;
}

/* .logs_box {
    width: 100% !important;
    max-height: 20vw;
} */

.logs_box .disable img {
    opacity: 0.5;
    cursor: auto;
}

.logs_box .starIcon {
    position: absolute;
    right: 14px;
    top: 14px;
}

.topDomainTable {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    /* Two equal columns and one smaller column */
    gap: 10px;
}

.domainLabel {
    color: #6fbfe1;
}

.graph-log-row:hover .white_overlay::after {
    /* background: #6FBFE112; */
    background: linear-gradient(0deg, #6fbfe103 3%, rgba(255, 255, 255, 0) 6%, #6fbfe10d 100%);
}

.white_overlay:after {
    width: 100%;
    left: 0;
    height: 90px;
    top: 0;
    position: absolute;
    content: '';
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
}

.no_content {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    position: absolute;
    width: 100%;
    top: 50%;
    white-space: nowrap;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: rgba(111, 191, 225, 1);
}

.border_hover .corner {
    width: 16px;
    height: 16px;
    z-index: 1;
    position: absolute;
    border: 1px solid rgba(111, 191, 225, 0.5);
}

.border_hover .corner1 {
    left: 0;
    top: 0;
    border-right: 0 !important;
    border-bottom: 0 !important;
}

.border_hover .corner2 {
    right: 0;
    top: 0;
    border-left: 0 !important;
    border-bottom: 0 !important;
    display: none;
}

.border_hover .corner3 {
    left: 0;
    bottom: 0;
    border-right: 0 !important;
    border-top: 0 !important;
    display: none;
}

.border_hover .corner4 {
    right: 0;
    bottom: 0;
    border-left: 0 !important;
    border-top: 0 !important;
    display: none;
}

.border_hover:hover .corner {
    width: 10px;
    height: 10px;
    border-width: 2px;
}

.border_hover:hover .corner2 {
    display: block !important;
}

.border_hover:hover .corner3,
.border_hover:hover .corner4 {
    display: block !important;
}

.webCategory {
    width: 268px;
    height: 56px;
    top: 20px;
    left: 20px;
    z-index: 9;
    padding-right: 10px;
    overflow: hidden;
}

.webCategory .content > div {
    margin: 7px 0 7px 7px;
    padding-right: 5px;
}

.webCategory:hover {
    background: rgba(111, 191, 225, 0.072);
}

.webCategory .content p {
    width: 104px;
    font-family: Poppins;
    font-size: 10px;
    line-height: 10px;
    height: 8px;
    font-weight: 500;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: rgb(111, 191, 225);
}

.webCategory .content .color_box {
    width: 96px;
    height: 8px;
}

.webCategory .content .color_box .color1 {
    width: 3px;
    height: 100%;
    background: rgba(3, 169, 244, 0.5);
}

.webCategory .content .color_box .color2 {
    width: 3px;
    height: 100%;
    background: rgba(133, 9, 231, 0.5);
}

.webCategory .content .color_box .color3 {
    width: 3px;
    height: 100%;
    background: rgba(205, 220, 57, 0.5);
}

.webCategory .content .per {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    height: 8px;
    text-align: right;
    color: rgba(111, 191, 225, 1);
}

.webCategory .content {
    overflow-y: auto;
    height: 100%;
}

.webCategory .content::-webkit-scrollbar {
    width: 4px;
    display: none;
}

.webCategory .content:hover::-webkit-scrollbar {
    display: block;
}

.webCategory .content::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: rgba(111, 191, 225, 0.75);
}

.webCategory .content::-webkit-scrollbar-track {
    background-color: rgba(111, 191, 225, 0.41);
    border-radius: 7px;
}

.engagement_per {
    z-index: 9;
    min-width: 40px;
    height: 24px;
}

.engagement_per .content {
    width: 100%;
    height: 100%;
}

.engagement_per .no_content {
    color: #0f2bfb;
}

.engagement_per:hover .engagement_box {
    display: flex;
}

.engagement_box {
    min-width: fit-content;
    height: 32px;
    padding: 8px;
    position: absolute;
    border-radius: 7px;
    top: -35px;
    gap: 10px;
    display: none;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    display: none;
    background: rgba(0, 20, 255, 1);
}

.engagement_box p {
    font-family: Poppins;
    font-size: 10px;
    color: #ffffff;
    font-weight: 400;
    line-height: 15px;
    text-wrap: nowrap;
}

.engagement_box .per {
    min-width: 26px;
    display: flex;
    align-items: center;
    padding: 2px;
    height: 20px;
    justify-content: center;
    border-radius: 3px;
}

.engagement_box .per span {
    font-family: Poppins;
    font-size: 8px;
    font-weight: 500;
    line-height: 3px;
}

.engagement_box .per.minus_per {
    background: rgba(255, 237, 241, 1);
}

.engagement_box .per.minus_per span {
    color: rgba(254, 73, 106, 1);
}

.engagement_box .per.zero_per {
    background: rgba(243, 243, 245, 1);
}

.engagement_box .per.zero_per span {
    color: rgba(15, 16, 68, 0.75);
}

.engagement_box .per.plus_per {
    background: rgba(233, 255, 225, 1);
}

.engagement_box .per.plus_per span {
    color: rgba(89, 201, 50, 1);
}

.engagement_per .content p {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    text-align: center;
}

.engagement_per .content p span:nth-child(1) {
    color: rgba(15, 43, 251, 1);
}

.engagement_per .content p span:nth-child(2) {
    color: rgba(58, 110, 239, 1);
}

.engagement_per .content p span:nth-child(3) {
    color: #6fbfe1;
}

/* animation circle css */
.outset_loader .neon-circle,
.outer_img .neon-circle {
    background-image: linear-gradient(#ffffff, #ffffff), linear-gradient(to bottom, #0014ff, #73dee1);
}

.neon-circle {
    width: 156px;
    height: 156px;
    border-radius: 50%;
    box-shadow: 0 0 10px 0px rgba(96, 191, 244, 0.5), 0 0 10px 0px rgba(96, 191, 244, 0.5),
        0 0 10px 0px rgba(96, 191, 244, 0.5);
    border: 2px solid transparent;
    background-image: linear-gradient(#11113f, #11113f), linear-gradient(to bottom, #0014ff, #73dee1);
    background-origin: border-box;
    background-clip: content-box, border-box;
    transition: box-shadow 0.5s ease, transform 0.5s ease, border-style 0.3s ease;
    /* One transition declaration */
    -webkit-transition: box-shadow 0.5s ease, -webkit-transform 0.5s ease, border-style 0.3s ease;
    -moz-transition: box-shadow 0.5s ease, -moz-transform 0.5s ease, border-style 0.3s ease;
    -o-transition: box-shadow 0.5s ease, -o-transform 0.5s ease, border-style 0.3s ease;
}

#solar-system .neon-circle {
    width: 8.125vw;
    height: 8.125vw;
}

.neon-circle.idle {
    animation: idleRotate 4s linear infinite;
    -webkit-animation: idleRotate 4s linear infinite;
    /* Safari and Chrome */
    -moz-animation: idleRotate 4s linear infinite;
    /* Firefox */
    -o-animation: idleRotate 4s linear infinite;
    /* Opera */
    box-shadow: 0 0 5px 0px rgba(96, 191, 244, 0.5), 0 0 8px 0px rgba(96, 191, 244, 0.5),
        0 0 10px 0px rgba(96, 191, 244, 0.5);
}

@keyframes idleRotate {
    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes idleRotate {
    /* Safari and Chrome */
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes idleRotate {
    /* Firefox */
    to {
        -moz-transform: rotate(360deg);
    }
}

@-o-keyframes idleRotate {
    /* Opera */
    to {
        -o-transform: rotate(360deg);
    }
}

.neon-circle.thinking {
    animation: thinkingAnimation 2s infinite alternate ease-in-out;
    -webkit-animation: thinkingAnimation 2s infinite alternate ease-in-out;
    /* Safari and Chrome */
    -moz-animation: thinkingAnimation 2s infinite alternate ease-in-out;
    /* Firefox */
    -o-animation: thinkingAnimation 2s infinite alternate ease-in-out;
    /* Opera */
}

@keyframes thinkingAnimation {
    0% {
        box-shadow: 0 0 10px 0px rgba(0, 20, 255, 0.5);
        transform: rotate(0deg);
    }

    50% {
        box-shadow: 0 0 25px 0px rgba(104, 132, 255, 0.8);
        transform: rotate(180deg);
    }

    100% {
        box-shadow: 0 0 10px 0px rgba(255, 80, 181, 1);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes thinkingAnimation {
    /* Safari and Chrome */
    0% {
        box-shadow: 0 0 10px 0px rgba(0, 20, 255, 0.5);
        -webkit-transform: rotate(0deg);
    }

    50% {
        box-shadow: 0 0 25px 0px rgba(104, 132, 255, 0.8);
        -webkit-transform: rotate(180deg);
    }

    100% {
        box-shadow: 0 0 10px 0px rgba(255, 80, 181, 1);
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes thinkingAnimation {
    /* Firefox */
    0% {
        box-shadow: 0 0 10px 0px rgba(0, 20, 255, 0.5);
        -moz-transform: rotate(0deg);
    }

    50% {
        box-shadow: 0 0 25px 0px rgba(104, 132, 255, 0.8);
        -moz-transform: rotate(180deg);
    }

    100% {
        box-shadow: 0 0 10px 0px rgba(255, 80, 181, 1);
        -moz-transform: rotate(360deg);
    }
}

@-o-keyframes thinkingAnimation {
    /* Opera */
    0% {
        box-shadow: 0 0 10px 0px rgba(0, 20, 255, 0.5);
        -o-transform: rotate(0deg);
    }

    50% {
        box-shadow: 0 0 25px 0px rgba(104, 132, 255, 0.8);
        -o-transform: rotate(180deg);
    }

    100% {
        box-shadow: 0 0 10px 0px rgba(255, 80, 181, 1);
        -o-transform: rotate(360deg);
    }
}

.evaluateMode_img {
    width: 8.125vw;
    height: 8.125vw;
    position: absolute;
    top: 50%;
    z-index: -1;
    border-radius: 50%;
    transform: translateY(-50%);
}

.evaluateMode_orbit {
    width: 8.125vw;
    height: 8.125vw;
    border-radius: 50%;
    box-shadow: 0 0 10px 0px #73dde13d, 0 0 10px 0px #73dde13d, 0 0 10px 0px #73dde13d;
    border: 2px solid transparent;
    background-image: linear-gradient(#73dee1, #60f4c8), linear-gradient(to bottom, #cdfff0, #60f4c8);
    background-origin: border-box;
    animation: idleRotate 4s linear infinite;
}

.evaluateMode_orbit.thinking {
    animation: thinkingAnimationevaluateMode 2s infinite alternate ease-in-out;
    -webkit-animation: thinkingAnimationevaluateMode 2s infinite alternate ease-in-out;
    /* Safari and Chrome */
    -moz-animation: thinkingAnimationevaluateMode 2s infinite alternate ease-in-out;
    /* Firefox */
    -o-animation: thinkingAnimationevaluateMode 2s infinite alternate ease-in-out;
    /* Opera */
}

@keyframes thinkingAnimationevaluateMode {
    0% {
        box-shadow: 0 0 10px 0px #60f4c8;
        transform: rotate(0deg);
    }

    50% {
        box-shadow: 0 0 25px 0px #73dee1;
        transform: rotate(180deg);
    }

    100% {
        box-shadow: 0 0 10px 0px #6fbfe1;
        transform: rotate(360deg);
    }
}

@-webkit-keyframes thinkingAnimationevaluateMode {
    /* Safari and Chrome */
    0% {
        box-shadow: 0 0 10px 0px #60f4c8;
        transform: rotate(0deg);
    }

    50% {
        box-shadow: 0 0 25px 0px #73dee1;
        transform: rotate(180deg);
    }

    100% {
        box-shadow: 0 0 10px 0px #6fbfe1;
        transform: rotate(360deg);
    }
}

@-moz-keyframes thinkingAnimationevaluateMode {
    /* Firefox */
    0% {
        box-shadow: 0 0 10px 0px #60f4c8;
        transform: rotate(0deg);
    }

    50% {
        box-shadow: 0 0 25px 0px #73dee1;
        transform: rotate(180deg);
    }

    100% {
        box-shadow: 0 0 10px 0px #6fbfe1;
        transform: rotate(360deg);
    }
}

@-o-keyframes thinkingAnimationevaluateMode {
    /* Opera */
    0% {
        box-shadow: 0 0 10px 0px #60f4c8;
        transform: rotate(0deg);
    }

    50% {
        box-shadow: 0 0 25px 0px #73dee1;
        transform: rotate(180deg);
    }

    100% {
        box-shadow: 0 0 10px 0px #6fbfe1;
        transform: rotate(360deg);
    }
}

.orbit_bots {
    z-index: 1;
}

.top_logo {
    left: 50%;
    transform: translateX(-50%);
}

.tab_svg path {
    fill: #0f104480 !important;
}

.tab_svg:hover path {
    fill: #0f1044bf !important;
}

.Mui-selected svg path {
    fill: #0f1044bf !important;
}

#spaceName:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #b6a3ff inset !important;
    background-color: #b6a3ff !important;
    background-clip: border-box !important;
    padding: 0;
    margin: 0;
}

#spaceName:focus ~ .space-placeholder {
    display: none;
}

.tier_table h1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #0f1044;
    opacity: 0.75;
    cursor: default;
}

.tier_table h1:hover {
    opacity: 1;
}

.tier_table .sub-heading {
    font-family: Poppins;
    font-size: 12px;
    line-height: 18px;
    color: #0f1044;
    opacity: 0.75;
    cursor: default;
}

.tier_table .sub-heading:hover {
    opacity: 1;
}

#pos_box svg {
    z-index: 9999;
    font-size: 0.365vw;
}

#pos_box svg,
.circle_outer {
    z-index: 999;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.circle_outer {
    width: 0.365vw;
    height: 0.365vw;
}

.oversight_tab {
    width: max-content;
    display: flex;
    flex-wrap: wrap;
}

.novi_left_heading,
.navi_box .top_view {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: #0f1044bf;
}

.trigger_card {
    width: 292px !important;
    margin-left: 20px;
}

.trigger_card .card {
    width: 206px;
    margin-left: 20px;
}

.navi_left_side > div {
    gap: 20px;
}

.graph_box .graph_card,
.graph_box {
    /* width: 923px !important; */
    width: 100% !important;
}

.navi_box {
    width: 766px;
}

.navi_box .domain_box {
    height: 85vh;
    overflow-y: auto;
}

.top_domain {
    max-height: 50vh;
    overflow-y: auto;
}

.monitoring_box {
    max-height: 33vh;
    overflow-y: auto;
}

.analytics_left_tab {
    width: 179px;
    height: 100vh;
    top: 0;
    padding-top: 120px;
    z-index: 15;
    text-align: start;
    z-index: 99 !important;
    border-right: 1px solid rgba(15, 16, 68, 0.07) !important;
    position: fixed;
    background-color: #fafafa;
}

.analytics_left_tab a {
    padding-left: 10px;
    justify-content: start;
    font-weight: 500;
    margin-left: 10px;
    color: rgba(15, 16, 68, 0.5);
}

.analytics_left_tab a .MuiTouchRipple-root {
    display: none;
}

.analytics-right {
    padding-left: 220px !important;
    width: 1350px;
    margin-left: auto;
}

.analytics-right::-webkit-scrollbar {
    display: none;
}

@media (max-width: 1200px) {
    .analytics_left_tab {
        left: 0;
        z-index: 999 !important;
    }

    .analytics-right {
        padding-left: 160px !important;
    }
}

@media (max-width: 991px) {
    .oversight_tab {
        flex-direction: column;
    }

    .analytics_left_tab {
        left: 0;
        top: 120px;
        padding-top: 0;
    }

    .analytics_left_tab .MuiTabs-vertical {
        margin-top: 0;
    }
}

@media (max-width: 768px) {
    .navi_left_side > div {
        flex-direction: column;
        width: 100%;
    }

    .analytics_left_tab {
        top: 90px;
    }

    .scroll_div {
        height: calc(100vh - 200px);
        overflow-y: auto;
    }

    .analytics-right {
        padding-right: 15px;
        padding-left: 180px !important;
        height: calc(80vh + 150px);
        overflow-y: auto;
    }
}

.scroll_div::-webkit-scrollbar {
    display: none;
}

@media (max-width: 575px) {
    .graph_box .graph_card {
        width: fit-content;
    }
}

.create_button p {
    color: #0f1044bf;
}

.create_button:hover svg {
    fill: #0f104480;
}

.create_button:hover p {
    color: #0f104480 !important;
}

.top_menu {
    width: 159px;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
}

.details-blue-box .next_btn {
    right: 15px;
    bottom: 5px;
}

.details-blue-box .next_btn p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin-right: 10px;
    background: linear-gradient(90deg, #4a95d6 0%, #bc54ff 143%, #f14f98 284.57%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-size: 100%;
}

.empty_box {
    /* height: 80vh; */
    height: 360px;
    width: 100% !important;
}

.empty_graph {
    width: 128px;
    border-radius: 50%;
    height: 128px;
    margin: auto;
    background: #0f104408;
}

.empty_box h4 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: #0f1044bf;
    margin-top: 25px;
}

.empty_box p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 10px 0;
    text-align: center;
    color: #0f104480;
}

.empty_box p span,
.empty_box p b {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
    color: #0014ff80;
}

.empty_box p span {
    color: #0f104480 !important;
    text-transform: capitalize;
}

.empty_box button {
    width: 141px;
    height: 40px;
    font-family: Poppins;
    font-size: 12px;
    display: block;
    font-weight: 400;
    line-height: 18px;
    margin: auto;
    border-radius: 50px;
    text-align: center;
    color: #ffffff;
    margin-top: 30px;
}

.empty_box .navi_btn {
    background: #0014ff;
}

.empty_box .blueprint_btn {
    box-shadow: 0px 3px 6px 0px #a95ff840;
    background: linear-gradient(90deg, #4a95d6 0%, #bc54ff 53.5%, #f14f98 99.5%);
}

.blue_print_eva .protocol_input_box img {
    filter: invert(1);
}

.blue_print_eva .protocol_box .content span {
    color: #ffffff80;
}

.blue_print_eva .protocol_box .content:hover span {
    color: rgba(255, 255, 255, 1);
}

.protocol_box .protocol_last_type {
    width: 400px;
    left: 0;
    height: 30px;
    bottom: 0;
    position: absolute;
    background: rgb(255, 255, 255);
    transform: rotate(180deg);
    z-index: 999 !important;
}

.blue_print_eva .protocol_box .protocol_last_type {
    background: linear-gradient(0deg, rgba(15, 16, 68, 0) 0%, rgba(15, 16, 68, 0.027) 0%, rgb(15 16 68 / 55%) 100%);
}

.main_outside_eva .protocol_box .protocol_last_type {
    background-color: rgba(255, 255, 255, 0.37);
}

.blue_print_eva .main_protocol_group {
    top: -40px;
}

@media (max-width: 1400px) {
}

.left_side_button {
    height: fit-content;
    padding-right: 15px;
    border-right: 1px solid #6fbfe140;
}

.right_side_button {
    display: flex;
    gap: 15px;
    justify-content: center;
}

.right_side_button .head_btn {
    min-width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    padding: 0;
    background: none;
    border: none;
}

.right_side_button .delete_btn {
    width: 48px;
    height: 48px;
}

.right_side_button .publish_btn {
    border: 0;
    border-radius: 5px;
    margin: 0;
    height: 32px;
    background: linear-gradient(90deg, #4a95d6 0%, #bc54ff 53.5%, #f14f98 99.5%);
}

.right_side_button .publish_btn:hover,
.right_side_button .publish_btn:focus {
    border: 0;
    background: linear-gradient(90deg, #4391d4 0%, #a746e7 53.5%, #e4468d 99.5%) !important;
}

.visit_count:hover .tooltip_box {
    display: flex;
}

.tooltip_box {
    min-width: fit-content;
    height: 32px;
    padding: 0 7px;
    top: 32px;
    left: -15px;
    position: absolute;
    border-radius: 7px;
    display: none;
    padding: 0 5px;
    margin: 0;
    align-items: center;
    background: #0014ff;
}

.tooltip_box p {
    font-family: Poppins;
    font-size: 10px;
    text-wrap: nowrap;
    font-weight: 400;
    display: block;
    line-height: 15px;
    color: #ffffff;
}

.tooltip_box p b {
    font-weight: 600;
}

.blue_print_top .eva_header {
    background: rgba(111, 191, 225, 0.1);
    max-width: fit-content;
    border-radius: 7px;
    padding: 10px;
    height: 48px;
    align-items: center;
}

.eva_header .delete_btn {
    position: absolute;
    bottom: 20px;
    background-color: rgba(111, 191, 225, 0.2) !important;
    right: 15px;
    z-index: 9;
}

.isarchive_btn:hover svg path,
.eva_header .delete_btn:hover svg path,
.close_btn:hover svg path,
.restart_btn:hover svg path {
    fill: #6fbfe1;
}

.isarchive_btn:hover,
.eva_header .delete_btn:hover,
.restart_btn:hover {
    background: rgba(111, 191, 225, 0.1);
}

.orbit_eva_btn img {
    height: 12px;
    width: 12px;
    opacity: 0.7;
}

.orbit_eva_btn:hover {
    background: rgba(111, 191, 225, 0.1);
}

.orbit_eva_btn:hover img {
    opacity: 1;
}

.restart_btn svg path,
.eva_header .delete_btn svg path,
.isarchive_btn svg path {
    fill: #6fbfe1bf;
}

.close_btn svg path {
    fill: rgba(111, 191, 225, 0.75);
}

.isarchive_disable_btn svg {
    transform: rotate(180deg);
}

.isarchive_disable_btn.head_btn {
    border-color: #ffffff40 !important;
    background: transparent !important;
}

.isarchive_disable_btn.head_btn:hover {
    border-color: #ffffff80 !important;
}

.isarchive_disable_btn svg path {
    fill: #ffffff80 !important;
}

.isarchive_disable_btn:hover svg path {
    fill: #ffffffbf !important;
}

.eva_header .tooltip_box {
    padding: 0 7px;
    background: #0014ff;
    min-width: fit-content;
}

.eva-test-btn .tooltip_box,
.publish_btn .tooltip_box,
.head_btn .tooltip_box {
    left: 50%;
    top: 35px;
    transform: translateX(-50%);
}

.publish_btn .tooltip_box p span {
    padding-left: 13px;
    position: relative;
}

.publish_btn .tooltip_box p span::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: #4abc90;
}

.eva-test-btn:hover .tooltip_box,
.publish_btn:hover .tooltip_box,
.head_btn:hover .tooltip_box {
    display: flex;
    margin: 0;
}

.delete_btn .tooltip_box {
    top: -35px;
}

.right_side_button .publish_btn_disable {
    background: #8d8d8d80 !important;
    padding: 6px;
}

.right_side_button .publish_btn_disable:hover {
    background: #8d8d8d80 !important;
}

.right_side_button .eva_close_btn {
    padding: 0 !important;
    border: none;
}

.right_side_button .publish_btn_disable:focus {
    background: #8d8d8d80 !important;
}

@media (max-width: 1600px) {
    .eva-orbit-space {
        scale: 0.7;
    }

    .graph-log-row {
        max-width: 300px;
    }
}

@media (max-width: 1300px) {
    .eva-orbit-space {
        scale: 0.6;
    }

    .graph-log-row {
        max-width: 250px;
    }

    .makeStyles-headerTitle-14 {
        padding: 0;
    }
}

@media (max-width: 1024px) {
    .eva-orbit-space {
        scale: 0.55;
    }
}

.isShield_start {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.isShield_start .onHover_msg {
    top: 20px;
    background: rgba(0, 20, 255, 1) !;
}

.isShield_start .onHover_msg::before {
    content: none;
}

.navi_empty_heading {
    position: absolute;
    left: 5px;
    top: 20px;
    padding: 0 20px;
    background-color: #ffff;
}

.navi_empty_heading h1 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: rgba(15, 16, 68, 0.75);
}

.navi_info_box {
    width: 286px;
    height: max-content;
    padding: 8px;
    border-radius: 7px;
    background: rgba(0, 20, 255, 1);
    font-family: Poppins;
    font-size: 10px;
    position: absolute;
    left: 92%;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 400;
    display: none;
    line-height: 15px;
    color: rgba(255, 255, 255, 1);
}

.navi_empty_heading .info_icon:hover ~ .navi_info_box {
    display: block;
}

.select_loader {
    width: 17px;
    height: 17px;
    border: 2px solired;
    border: 2px solid;
    border-right-color: #ea4790;
    border-bottom-color: #b149f4;
    border-left: 0;
    border-top: 0;
    position: absolute;
    left: 45%;
    transform: translateX(-50%);
    animation: spinLoading 0.8s linear infinite;
    border-radius: 50%;
}

.xls_icon {
    width: 30px;
    height: 30px;
}

.xls_icon .select_loader {
    border-color: #fff !important;
    left: 20%;
}

.main_outside_eva {
    height: 100%;
    margin: 0 24px 24px 24px;
    height: 100%;
    overflow: auto;
}

.publish_eva {
    height: inherit !important;
}

.publishedEva_exist {
    height: 100%;
}

#top_tab {
    position: fixed;
    z-index: 9999999 !important;
    top: 0;
    width: max-content;
    left: 50%;
    transform: translate(-50%);
    background-color: transparent;
    display: flex;
    height: 56px;
    justify-content: center;
}

#top_tab .tab_link {
    margin-top: 0;
    height: 100%;
    display: flex;
    align-items: center;
}
@media (max-width: 768px) {
    .tab_link {
        width: 160px;
        margin: auto;
    }

    .main_outside_eva {
        background-color: #ffff;
        border: 1px solid #bebdbd46;
        width: 100%;
        border-radius: 5px;
        height: 75vh;
        padding: 15px;
        overflow-y: auto;
    }

    .outside_eva {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
    }

    .publish-eva-dynamic-text {
        height: 100%;
    }

    .eva_info_box {
        position: relative;
        width: 90%;
        z-index: 9;
        margin: 20px auto;
    }

    .eva_info_box h2 {
        font-family: Poppins;
        font-size: 15px;
        font-weight: 500;
        line-height: 14px;
        margin-bottom: 10px;
        text-align: left;
        color: #0f1044bf;
    }

    .eva_info_box p {
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        text-align: left;
        color: #0f104480;
        word-break: break-all;
    }

    .web_visit {
        position: relative;
        top: 0;
        left: 0;
        width: 90%;
        margin: 20px auto;
    }

    .publish-eva-dynamic-text {
        max-width: 90% !important;
        margin: 40px auto;
    }

    .main_protocol_group {
        position: relative;
        margin: 20px auto;
        width: 90%;
        top: 0;
        left: 0;
        transform: none !important;
    }

    .main_protocol_group .protocol_box .main_protocol_content .content {
        width: 90%;
    }

    .show_protocol_list .protocol_box .main_protocol_content .content::before {
        width: 90% !important;
    }

    .graph-log-row {
        position: relative;
        max-width: 90%;
        left: 0;
        margin: 10px auto;
        top: 0;
    }
}

@media (max-width: 1500px) {
    .blue_print_eva {
        margin: 0;
    }
}

.underline-custom {
    text-decoration: underline;
    text-decoration-color: rgba(15, 16, 68, 0.75);
    font-size: 12px;
    font-weight: 500;
    height: 16px;
}

.underline-blue-ryb {
    text-decoration: underline;
    text-decoration-color: #0014ff;
}

.nsa-wrapper {
    position: relative;
    padding: 1.6px;
    /* Adjust to control the outer spacing */
    border-radius: 50px;
    background: linear-gradient(89.15deg, rgba(57, 188, 249, 0.2) 0%, rgba(19, 237, 101, 0.2) 101.53%);
    overflow: hidden;
    width: 100%;
}

.space_logo_box {
    flex-shrink: 0;
    /* Prevent shrinking */
    width: 24px;
    /* Fixed size for the logo */
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: #ddd;
    overflow: hidden;
}

.nsa-wrapper p {
    font-size: 5px;
}

.nsa-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    background: linear-gradient(90deg, rgba(96, 191, 244, 0.5) 0%, rgba(90, 73, 254, 0.5) 100%);
    z-index: -1;
    padding: 1px;
    box-sizing: border-box;
}

.nsa-container {
    align-items: center;
    justify-content: space-between;
    position: relative;
    /* background: linear-gradient(90deg, #eff9fe 0%, #efedff 100%); */
    background: linear-gradient(0deg, #FFFFFF, #FFFFFF),linear-gradient(88.43deg, rgba(5, 121, 174, 0.1) 10.31%, rgba(4, 242, 101, 0.1) 100%);
    background-blend-mode: overlay;
    padding: 2px 8px;
    display: flex;
    border-radius: 50px;
}

.nsa-text-container {
    display: flex;
    z-index: 1;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
}

.nsa-container::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari, and Opera */
}

.nsa-text-container:hover .nsa-text {
    animation-name: scroll-text;
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    text-decoration: underline;
}

@keyframes scroll-text {
    0% {
        transform: translateX(0%);
    }

    90% {
        transform: translateX(-100%);
    }

    95% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(0%);
    }
}

.member_search {
    border-radius: 10px;
    max-width: 448px;
    overflow: hidden;
    position: relative;
    padding: 0 30px;
    margin-bottom: 10px;
    border: 1px solid #0f10441a;
    background: #ffffff;
    height: 40px;
}

.member_search input::placeholder,
.member_search input {
    width: 100%;
    font-family: Poppins;
    font-size: 12px;
    color: #0f104480;
    font-weight: 400;
    line-height: 18px;
    text-align: left;

    height: 40px;
}

.member_search img {
    position: absolute;
    left: 10px;
    opacity: 0.8;
    top: 50%;
    transform: translateY(-50%);
}

.organisation-form .rounded-full {
    border-radius: 10px;
}

.eva-first-slider-planet .makeStyles-placeHolder-31 {
    color: rgba(255, 255, 255, 0.75) !important;
}

.current_text {
    color: rgba(96, 244, 200, 1) !important;
}

.current_line {
    background-color: rgba(96, 244, 200, 0.5) !important;
}

/* eva header modify  */

.blue_print_top {
    display: flex;
    justify-content: flex-end;
    gap: 80px;
    height: 74px;
    padding: 10px;
}

.z-999 {
    z-index: 999;
}

.overview_info:hover ~ .main_outside_eva {
    z-index: 99 !important;
}

@media screen and (max-height: 700px) {
    #solar-system {
        transform: scale(0.8) !important;
    }

    .evaluate-hint-container {
        padding: 7px !important;
    }

    .evaluate-hint-container p {
        font-size: 7px;
    }

    .evaluate-header {
        margin-bottom: 15px;
    }

    .main_protocol_group .protocol_box {
        max-height: 55vh;
    }
}

@media screen and (max-height: 600px) {
    #solar-system {
        height: calc(90vh - 300px);
        transform: scale(0.7) !important;
    }

    .evaluate-hint-container {
        padding: 7px !important;
    }

    .evaluate-header {
        margin-bottom: 15px;
    }

    .evaluate-hint-container p {
        font-size: 7px;
    }
}

.eva_plus_btn {
    position: absolute;
    bottom: -50px;
    left: 50%;
}

.eva-user-enabled {
    background: linear-gradient(90deg, #0014ff 0%, #6fbfe1 50%, #75ece2 100%);
}

.eva-user-disabled {
    background: linear-gradient(244.4deg, #d1d1d1 6.11%, #898989 89.24%);
}

.member-invited {
    color: #c27801bf;
    font-size: 12px;
    font-weight: 500;
}

.active_blueprint .Mui-selected {
    background: rgba(17, 17, 63, 1) !important;
}

.active_blueprint .Mui-selected svg path {
    fill: #fff !important;
}

.active_blueprint.evaluation_mode .Mui-selected svg path {
    fill: rgba(96, 244, 200, 1) !important;
}

.pick-up-label {
    width: 98%;
    margin-right: auto;
}

.create_blueprint_btn {
    max-width: 158px;
    width: 100%;
    background: rgba(0, 20, 255, 1);
    height: 40px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    border: 0;
    border-radius: 10px;
    color: rgba(255, 255, 255, 1);
    outline: none;
}

.warning_alert {
    border: 1px solid #ffc400;
    background-color: #ffe695;
    border-radius: 5px;
}

.warning_alert h1 {
    color: #000;
}

.warning_alert svg {
    width: 20px;
    height: 20px;
}

.tooltip-box {
    max-width: 300px;
    padding: 8px 10px !important;
    border-radius: 8px !important;
    background: rgba(0, 20, 255, 1) !important;
    height: auto;
    z-index: 999 !important;
}

.tooltip-box .react-tooltip-arrow {
    display: none;
}

.tooltip-box p {
    font-size: 10px;
    font-weight: 400;
    border-radius: 10px;
    line-height: 15px;
    color: rgba(255, 255, 255, 1);
}

.continue_btn:focus {
    background: #4631e7;
}

.pass_eye_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    width: 20px;
    height: 17px;
}

.pass_eye_icon:focus {
    border-radius: 2px;
    opacity: 1 !important;
    outline: 2px solid #000;
}

.pass_eye_icon img {
    width: 16px;
    text-align: center;
    height: 16px;
}

.state_of_entities {
    top: 10px;
    border-radius: 7px;
    padding: 4px;
    box-shadow: 0px 4px 10px 0px #0f10441f;
    z-index: 8;
    width: max-content;
    background: #0f104408;
    height: 48px;
    left: 10px;
}

.state_of_entities .shield_btn {
    font-weight: 500;
    gap: 8px;
    outline: none;
    min-width: 91px;
    width: auto;
    font-size: 12px;
    color: #0014ff;
    height: 40px;
    padding: 0px 6px;
}

.state_of_entities .upcoming_btn {
    min-width: 127px;
    width: auto;
    border: 0;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    height: 40px;
    color: #0f104426;
    background-color: transparent;
    padding: 0px 6px;
}

.state_of_entities .upcoming_btn p {
    word-break: break-all;
    word-break: keep-all;
    display: block;
}

.state_of_entities .upcoming_btn span {
    bottom: 6px;
    color: #0014ff;
    font-size: 6px;
    font-weight: 500;
    line-height: 9px;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip-box.show_arrow .react-tooltip-arrow {
    display: block;
}

.create_eva_input input,
.create_eva_input textarea,
.create_eva_input {
    width: 100%;
    overflow: hidden;
    margin: auto;
}

.create_eva_input p {
    font-family: Poppins;
    line-height: 24px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.create_eva_input.title p {
    font-weight: 600;
}

.create_eva_input .title_text {
    white-space: nowrap;
}

.create_eva_input input,
.create_eva_input textarea,
.create_eva_input input:focus {
    background: transparent;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}

.create_eva_input span {
    color: red;
}

.create_eva_input.title input,
.create_eva_input.title textarea .create_eva_input.title p {
    font-size: 16px;
    font-weight: 600;
}

.create_eva_input.description input,
.create_eva_input.description textarea,
.create_eva_input.description p {
    font-size: 12px;
    word-wrap: break-word;
    font-weight: 400;
    margin-top: 5px;
    line-height: 18px;
}

.create_eva_input textarea {
    border: 0;
    outline: 0;

    word-wrap: break-word;
}

.north-star-addon-container {
    background: linear-gradient(90deg, #eff9fe 0%, #efedff 100%);
    border-radius: 15px;
    padding: 16px;
    margin: 2.5rem 0;
    position: relative;
    z-index: 1;
}

.north-star-addon-container:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 15px;
    background: linear-gradient(90deg, rgba(96, 191, 244, 0.5) 0%, rgba(90, 73, 254, 0.5) 100%);
    padding: 1px; /* Adjust for border thickness */
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.ns-protocolType .icon {
    width: 16px;
    height: 16px;
    border: 1px solid;
    border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.075) 100%);
    border-image-slice: 1 !important;
    box-shadow: 0px 5px 15px 0px #ffffff26 inset;
    background: #ffff;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
}

.ns-protocolType .icon-text {
    background: linear-gradient(244.4deg, #c560f4 6.11%, #5a49fe 89.24%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 500;
}

.ns-protocolType-container {
    background: linear-gradient(244.4deg, rgba(197, 96, 244, 0.05) 6.11%, rgba(90, 73, 254, 0.05) 89.24%);
    padding: 8px;
    border-radius: 15px;
    gap: 8px;
}

.delete-dialog {
    border-radius: '15px';
    width: '100%';
    max-width: '656px';
    padding: '32px 24px';
    background-color: '#fff';
}

.file-error-box {
    width: 31.667vw;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(0deg, rgba(0, 20, 255, 0.25) -42.28%, rgba(0, 20, 255, 0) 42.28%);
}

.file-error {
    display: flex;
    padding: 21px 20px;
    gap: 8px;
}

.file-error-msg {
    color: rgba(255, 255, 255, 0.75);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
}

/* left and right round corner =========== */

.sub_header {
    min-width: 320px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 0 14px 16px 14px;
    margin: 0 auto 20px;
    position: relative;
    background: rgba(238, 238, 242, 1);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}
.sub_header .sub_header_content {
    min-width: 292px;
    height: 48px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 2px 4px 0px rgba(15, 16, 68, 0.12);

    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sub_header .sub_header_content h6 {
    font-family: Poppins;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: rgba(15, 16, 68, 0.5);
}

.sub_header .left_round,
.sub_header .right_round {
    width: 80px;
    height: 50px;
    position: absolute;
    top: 0;
}
.sub_header .left_round {
    left: -80px;
}
.sub_header .right_round {
    right: -80px;
}

.sub_header .left_round::before,
.sub_header .right_round::before {
    position: absolute;
    content: '';
    width: 80px;
    top: 0;
    z-index: 9;
    height: 50px;
    background: rgb(244 244 247);
}
.sub_header .left_round::before {
    border-top-right-radius: 15px;
}
.sub_header .right_round::before {
    border-top-left-radius: 15px;
}
.sub_header_empty {
    height: 20px;
}

.sub_header .left_round::after,
.sub_header .right_round::after {
    position: absolute;
    content: '';
    width: 80px;
    height: 50px;
    top: -15px;
    z-index: 1;
    background: #eeeef2;
}
.sub_header .left_round::after {
    border-top-right-radius: 15px;
}
.sub_header .right_round::after {
    border-top-left-radius: 15px;
}
.main_components {
    padding-top: 4rem;
}
.footer .logo {
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
}
.disabled_menu #top_tab {
    opacity: 0.5;
}
@media (max-width: 768px) {
    .main_eva_component {
        padding: 0;
    }
}

/* left and right round corner =========== */
/* Applies border to all children except the last one */
.lastChildBorder {
    border-bottom: 1px solid #5A44FF1A;
}

.childBorder {
    border-bottom: 1px solid #5A44FF1A;
}

/* Removes border from the last child */
.lastChildBorder:last-child {
    border-bottom: none;
}

.hover-auto-scroll:hover {
    animation-name: scroll-text;
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    text-decoration: underline;
}

.for-testing {
    background: #f00;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
}
