.three-dot-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    /* Space between dots */
    height: 50px;
    /* Adjust as needed */
}

.dot {
    width: 10px;
    /* Adjust the size of the dots */
    height: 10px;
    background-color: #FFFF;
    /* Adjust color as needed */
    border-radius: 50%;
    animation: bounce 1.5s infinite ease-in-out;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}