.trends_box {
    width: 100%;
    height: 100%;
    /* min-height: 170px; */
}

.trends_protocol {
    width: 100%;
    background: #ffffff;
    border-radius: 15px;
    padding: 24px 24px 10px 24px;
    height: 100%;
}
.trends_protocol_box {
    padding-right: 5px;
}
.scroll_trend_protocol {
    overflow-y: auto;
    height: 100%;
    padding-right: 19px;
    overflow-x: hidden;
}
.trends_protocol_list {
    gap: 15px;
    display: flex;
}
.trends_box .error_box {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.trends_box .empty_box {
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.trends_box .empty_box > div {
    overflow-y: auto;
    width: 100%;
}
.risk_heatmap {
    height: 93%;
    overflow-y: auto;
    overflow-x: hidden;
}
.risk_box {
    width: 100%;
    display: flex;
    height: calc(53% + 14px);
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2%;
}

.main_grid_box {
    height: 121px;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    transition: height 0.3s ease-in-out;
}
.main_grid_box.less_height {
    height: 35px;
    transition: height 0.3s ease-in-out;
}
.main_grid_box h6 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(15, 16, 68, 0.5);
}

.trends_protocol .grid_box {
    gap: 9px;
    width: 100%;
    display: flex;
    padding-right: 10px;
    padding-bottom: 15px;
    flex-wrap: wrap;
    justify-content: space-between;
}
.trend_top_box .filter_box {
    background-color: transparent;
}
.trend_top_box {
    gap: 15px;
    width: max-content;
}
.trend_top_box .search_box {
    width: 0;
    padding-right: 0;
    margin-right: 15px;
    cursor: pointer;
    border-bottom: 1px solid rgba(15, 16, 68, 0.1);
}
.search_box.show_search {
    width: 250px !important;
    padding-right: 10px;
    border-bottom: 1px solid rgba(15, 16, 68, 0.1);
}
.trend_top_box .search_box::before {
    content: '';
    position: absolute;
    right: -15px;
    height: 100%;
    width: 1px;
    background-color: rgba(15, 16, 68, 0.1);
}

.trend_top_box .search_box:hover {
    border-color: rgba(15, 16, 68, 0.2);
}
.trend_top_box .search_box .searchIocn {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
}
.trend_top_box .search_box input::placeholder {
    color: rgba(15, 16, 68, 0.5);
}
.trend_top_box .search_box:hover input::placeholder {
    color: rgba(15, 16, 68, 0.75);
}
.trend_top_box .search_box .searchIocn svg path {
    fill: rgba(15, 16, 68, 0.5);
}
.trend_top_box .search_box .searchIocn:hover svg path {
    fill: rgba(15, 16, 68, 0.75);
}
.trend_top_box .search_box input::placeholder,
.trend_top_box .search_box input {
    font-family: Poppins;
    font-size: 12px;
    height: 100%;
    color: rgba(15, 16, 68, 0.75);
    width: 100%;
    font-weight: 400;
    line-height: 18px;
}

.trend_top_box .type_box button {
    box-shadow: 0px 5px 15px 0px rgba(255, 255, 255, 0.15) inset;
    background: rgba(255, 255, 255, 1);
    width: 24px;
    border-radius: 5px;
    display: flex;
    border: 1px solid rgba(15, 16, 68, 0.1);
    height: 24px;
    align-items: center;
    justify-content: center;
}

.trend_top_box .type_box button .tooltip_box {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    top: -38px;
    white-space: nowrap;
    line-height: 15px;
    text-align: left;
    color: #fff;
}
.trend_top_box .type_box button:hover .tooltip_box {
    display: flex !important;
}
.trend_top_box .type_box button:hover {
    border: 1px solid rgba(15, 16, 68, 0.2);
}
.trend_top_box .type_box button.active {
    border: 1px solid rgba(0, 20, 255, 1);
}
.trend_top_box .type_box button img {
    width: 100%;
    height: 15px;
    object-fit: contain;
    scale: 1.3;
    margin-top: 3px;
}

.trends_protocol .grid_box .box {
    max-width: 49%;
    width: 100%;
    background: rgba(255, 255, 255, 1);
    height: auto;
    cursor: pointer;
    align-items: center;
    padding: 0 14px;
    height: 32px;
    border: 1px solid rgba(15, 16, 68, 0.1);
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
}
.trends_protocol .grid_box .box:hover {
    box-shadow: 0px 2px 6px 0px rgba(15, 16, 68, 0.15);
}
.trends_protocol .grid_box .box.active_box {
    box-shadow: 0px 2px 6px 0px rgba(15, 16, 68, 0.15) !important;
}



.trends_protocol .trends_head h2 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    position: relative;
    text-align: left;
    color: #0f1044bf;
}

.trends_protocol .trends_head h2 svg:hover ~ .navi_info_box {
    display: block;
}
.trends_protocol .trends_head h2 svg path {
    fill: #0f104480;
}

.trends_protocol .trends_head h2 svg:hover path {
    fill: #0f1044bf;
}

.trends_protocol .trends_head h2 .navi_info_box {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
    left: 103% !important;
}
.trends_box .main_drop_down .select_drop_down {
    height: 32px;
    display: flex;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.trends_box .main_drop_down .select_drop_down > div > div {
    padding: 8px 34px 8px 14px;
    width: 100%;
    color: rgba(15, 16, 68, 0.75);
}
.trends_box .main_drop_down .select_drop_down > div > div[aria-expanded='true'] {
    background: rgba(15, 16, 68, 0.03);
    color: rgba(15, 16, 68, 1) !important;
}
.trends_box .main_drop_down .select_drop_down:hover > div > div {
    color: rgba(15, 16, 68, 1);
    background: rgba(15, 16, 68, 0.03);
}

#menu- .MuiPopover-paper {
    border: none !important;
    background: #ffffff !important;
    box-shadow: 0px 6px 16px 0px #0f104429 !important;
}
#menu- .MuiPopover-paper::-webkit-scrollbar {
    display: none;
}

#menu- .MuiMenu-list li {
    padding: 0;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
}
#menu- .MuiMenu-list li > div {
    overflow: hidden;
    text-overflow: ellipsis;
}
.filter_box {
    height: 24px;
    border-radius: 7px;
    padding: 2px;
    background: rgba(15, 16, 68, 0.04);
}
.metric_filter .filter_box {
    padding: 16px;
}

.filter_box .filter_btn {
    width: 32px;
    height: 20px;
    border-radius: 7px;
    font-family: Poppins;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;
    text-align: center;
    color: rgba(15, 16, 68, 0.75);
}

.trends_box .back-btn {
    transform: rotate(180deg);
    width: auto;
    padding: 8px;
    height: 24px;
    border-radius: 5px;
    font-family: Poppins;
    font-size: 12px;
    display: flex;
    background: rgba(15, 16, 68, 0.04);
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 15px;
    cursor: pointer;
    text-align: center;
    color: rgba(15, 16, 68, 0.75);
}

.trends_box .back-btn svg {
    opacity: 0.7;
}

.trends_box .back-btn:hover svg {
    opacity: 1;
}

.filter_box .filter_btn:hover .tooltip_box {
    display: flex;
    left: 50%;
    top: -39px;
    transform: translateX(-50%);
    background: #0014ffbf;
    height: 32px !important;
    min-width: max-content;
    padding: 0 7px;
    z-index: 9999;
}
.filter_box .filter_btn .tooltip_box {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: center;
    color: #ffffff;
}
/* .filter_box .filter_btn.active {
    box-shadow: 0px 2px 2px 0px rgba(15, 16, 68, 0.15);
    background: rgba(255, 255, 255, 1);
    color: rgba(15, 16, 68, 0.75);
} */

.filter_box .filter_btn.active {
    background: rgba(255, 255, 255, 1);
    color: rgba(15, 16, 68, 1) !important;
}
.filter_box .filter_btn:hover {
    color: rgba(15, 16, 68, 1);
}
.filter_box .filter_btn.active:hover {
    color: rgba(15, 16, 68, 1);
}
.chart_box {
    width: 100%;
    margin-top: 10px;
    height: 35px;
}
.chart_box canvas {
    height: 35px !important;
}

.trends_protocol_list .grid_box .box h1 {
    display: flex;
    gap: 10px;
    align-items: center;
}

.trends_protocol_list .grid_box .box h1 span {
    font-family: Poppins;
    font-weight: 500;
    line-height: 15px;
    max-width: 250px;
    color: rgba(15, 16, 68, 0.75);
    font-size: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.trends_protocol_list .grid_box .box h1 img {
    height: min-content;
    margin-left: -7px;
}
.trends_protocol_list .grid_box .box .left_line {
    position: absolute;
    left: 0;
    height: 100%;
    top: 0;
    width: 2px;
}
.trends_protocol_list .grid_box .box .trigger_no {
    border: 1px solid rgba(15, 16, 68, 0.1);
    width: 27px;
    height: 20px;
    cursor: pointer;
    font-family: Poppins;
    color: rgba(15, 16, 68, 0.75);
    border-radius: 5px;
    background: rgba(15, 16, 68, 0.05);
    font-size: 10px;
    font-weight: 500;
    position: relative;
    line-height: 15px;
}
.trends_protocol_list .grid_box .box .trigger_no:hover .tooltip_box {
    display: flex;
    padding: 7px 8px;
    top: -24px;
    left: 50%;
    height: auto;
    transform: translateX(-50%);
    background: rgba(0, 20, 255, 0.75);
}
.trigger_box {
    border: 1px solid;
    padding: 3px;
    border: 1px solid rgba(15, 16, 68, 0.1);
    border-radius: 3px;
    width: 52px;
    height: 20px;
}

.trigger_box img {
    width: 10px;
}
.trigger_box span {
    color: rgba(15, 16, 68, 0.75);
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    display: block;
    text-align: left;
}
.trigger_box .triggerIcon {
    color: rgba(15, 16, 68, 0.75);
    font-weight: 500;
    font-size: 15px;
}

.trend_empty .empty_select {
    width: 247px;
    height: 16px;
    border-radius: 50px;
    background: #eeeeee7c;
    margin-bottom: 20px;
}

.trend_empty .shield_trend {
    width: 100%;
}

.trend_empty .navi_empty_heading {
    width: fit-content;
    top: 5px !important;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.navi_empty_heading svg path {
    fill: rgba(15, 16, 68, 0.5);
}
.navi_empty_heading svg:hover path {
    fill: rgba(15, 16, 68, 0.75);
}
@media (max-width: 768px) {
    .trend_empty .navi_empty_heading {
        top: 6px !important;
    }

    .navi_info_box {
        width: 190px;
        left: 83%;
    }
}

.main_grid_box .main_loader {
    position: relative;
    width: 100%;
    height: 240px;
}

.line_chart_box {
    height: calc(100% - 330px);
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    transition: height 0.3s ease-in-out;
}

.line_chart_box.less_height {
    height: calc(49% - 130px);
    transition: height 0.3s ease-in-out;
}
.show_metric_graph {
    height: calc(50% - 120px) !important;
}
.line_chart_box #chart {
    width: 98%;
    height: 100%;
    margin-left: auto;
}
.line_chart_box #chart > div {
    height: 100% !important;
}
.policy_color.active_box {
    border: 1px solid #ff9603 !important;
    background: linear-gradient(267.62deg, rgba(255, 221, 67, 0.1) -1.4%, rgba(255, 150, 3, 0.1) 98.21%),
        linear-gradient(0deg, #ffffff, #ffffff) !important;
}

.critical_thinking_color.active_box ,
.good_practice_color.active_box {
    border: 1px solid #12ed66 !important;
    background: linear-gradient(88.43deg, rgba(57, 188, 249, 0.1) 4.31%, rgba(4, 242, 101, 0.1) 100%),
        linear-gradient(0deg, #ffffff, #ffffff) !important;
}

.signal_color.active_box {
    border: 1px solid #c165dd !important;
    background: linear-gradient(267.62deg, rgba(193, 101, 221, 0.1) -1.4%, rgba(92, 39, 254, 0.1) 98.21%),
        linear-gradient(0deg, #ffffff, #ffffff) !important;
}

.custom_color.active_box {
    background: rgba(108, 109, 184, 0.03) !important;
    border: 1px solid rgba(108, 109, 184, 0.1) !important;
}

.apexcharts-xaxistooltip {
    display: none !important;
}

.filter_name span {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    text-transform: capitalize;
    text-align: center;
    position: absolute;
    bottom: -19px;
    left: 50%;
}
.no_trend.trends_protocol {
    border: 0;
    background: rgba(15, 16, 68, 0.02);
    width: 100%;
    height: 100%;
}
.no_trend {
    width: 924px;
}
.no_trend .empty_box p {
    margin: 0;
}
.no_trend .empty_box .blueprint_btn {
    box-shadow: 0px 3px 6px 0px rgba(15, 16, 68, 0.15);
    background: rgba(255, 255, 255, 1);
    border-radius: 7px;
    color: rgba(15, 16, 68, 0.75);
    font-size: 12px;
    margin-top: 10px;
}
.no_trend .empty_box {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.metric .metric_chart {
    margin-bottom: 0;
    position: relative;
    height: calc(100% - 140px);
    margin: 15px 0;
    border-radius: 5px;
    width: 98%;
    margin-left: auto;
}
.trend_loading_box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    left: 0;
    background: #ffffff94;

    top: 0;
}
.trend_loading_box #loading {
    z-index: 8888;
}

.chart_title {
    font-size: 10px;
    position: absolute;
    top: 50%;
    font-weight: 500;
    color: rgba(15, 16, 68, 0.75);
    left: 5px;
    transform: rotate(-90deg) translateX(-45%);
    transform-origin: left;
}

.metric_chart .chart_title {
    left: -11px;
}
.calender_filter {
    position: absolute;
    right: 0;
    top: 25px;
}

.calender_filter .filter_box {
    justify-content: space-around;
    min-width: 208px;
    height: 32px;
    border-radius: 50px;
    border: 1px solid rgba(15, 16, 68, 0.1);
    background-color: transparent;
}

.calender_filter .filter_box .filter_btn {
    font-family: Poppins;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: rgba(15, 16, 68, 0.5);
    height: 26px;
    width: 39px;
    border-radius: 50px;
}

@media (max-width: 1200px) {
    .calender_filter {
        position: relative;
        right: 11px;
        margin-bottom: 10px;
        top: unset;
    }
    .risk_box {
        margin: 25px 0;
    }
}
.apexcharts-canvas {
    height: 100% !important;
}

.risk_tooltip{
    font-size: 10px;
    font-weight: 400;
    border-radius: 10px;
    line-height: 15px;
    color: rgba(255, 255, 255, 1);
}
